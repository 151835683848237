import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContactsIcon from "@mui/icons-material/Contacts";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import WorkIcon from "@mui/icons-material/Work";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import AddchartIcon from "@mui/icons-material/Addchart";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PaidIcon from "@mui/icons-material/Paid";
import { NavLink, useNavigate } from "react-router-dom";

export default function SwipeableTemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <MenuList>
        <Button
          style={{
            borderRadius: "50px",
            width: "30px",
            minWidth: "30px",
            height: "30px",
            marginLeft: "10px",
            marginBottom: "20px",
          }}
          variant="contained"
          color="error"
          onClick={toggleDrawer(anchor, false)}
        >
          <CloseIcon fontSize="medium" />
        </Button>
        <Divider />

        <MenuItem onClick={() => navigate("/")}>
          <ListItemIcon>
            <HomeIcon fontSize="medium" style={{ color: "rgb(48, 32, 67)" }} />
          </ListItemIcon>
          Home
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            navigate("/about-us-digital-marketing-company-andheri-mumbai")
          }
        >
          <ListItemIcon>
            <GroupIcon fontSize="medium" style={{ color: "rgb(48, 32, 67)" }} />
          </ListItemIcon>
          <ListItemText> About Us </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/Gallery")}>
          <ListItemIcon>
            <GroupIcon fontSize="medium" style={{ color: "rgb(48, 32, 67)" }} />
          </ListItemIcon>
          <ListItemText>Gallery</ListItemText>
        </MenuItem>
        <Divider />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <DashboardCustomizeIcon
                fontSize="medium"
                style={{ color: "rgb(48, 32, 67)" }}
              />{" "}
              <span style={{ marginLeft: "7px" }}>Our Services</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <MenuList>
                <Divider />
                <MenuItem
                  onClick={() =>
                    navigate("/best-digital-marketing-agency-in-mumbai")
                  }
                >
                  <ListItemIcon>
                    <Diversity1Icon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>Digital Marketing</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate("/best-website-development-company-in-andheri")
                  }
                >
                  <ListItemIcon>
                    <LanguageIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>Website Development</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => navigate("/seo-agency-in-andheri-mumbai")}
                >
                  <ListItemIcon>
                    <TroubleshootIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>SEO</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate("/best-graphic-design-agency-in-mumbai")
                  }
                >
                  <ListItemIcon>
                    <AddchartIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>Graphic Design</ListItemText>
                </MenuItem>
                {/* <MenuItem>
                  <ListItemIcon>
                    <NewspaperIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <NavLink to="/pr-and-advertising">
                      {" "}
                      PR and Advertising
                    </NavLink>
                  </ListItemText>
                </MenuItem> */}
                {/* <MenuItem>
                  <ListItemIcon>
                    <AddAPhotoIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <NavLink to="/photography">Photography</NavLink>
                  </ListItemText>
                </MenuItem> */}
                {/* <MenuItem>
                  <ListItemIcon>
                    <YouTubeIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>
                    <NavLink to="/you-tube-marketing">
                      {" "}
                      You Tube Marketing
                    </NavLink>
                  </ListItemText>
                </MenuItem> */}
                <MenuItem onClick={() => navigate("/pr-and-advertising")}>
                  <ListItemIcon>
                    <PaidIcon
                      fontSize="medium"
                      style={{ color: "rgb(48, 32, 67)" }}
                    />
                  </ListItemIcon>
                  <ListItemText>PPC Services</ListItemText>
                </MenuItem>
              </MenuList>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Divider />
        <MenuItem onClick={() => navigate("/digital-marketing-blog")}>
          <ListItemIcon>
            <RssFeedIcon
              fontSize="medium"
              style={{ color: "rgb(48, 32, 67)" }}
            />
          </ListItemIcon>
          <ListItemText>Blogs</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => navigate("/careers-in-digital-marketing-andheri")}
        >
          <ListItemIcon>
            <WorkIcon fontSize="medium" style={{ color: "rgb(48, 32, 67)" }} />
          </ListItemIcon>
          <ListItemText>Careers </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={()=>navigate("/contact-us-digital-marketing")}>
          <ListItemIcon>
            <ContactsIcon
              fontSize="medium"
              style={{ color: "rgb(48, 32, 67)" }}
            />
          </ListItemIcon>
          <ListItemText>Contact Us</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleDrawer(anchor, true)}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
