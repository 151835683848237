import React, { useLayoutEffect, useState } from "react";

import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

import "../css/Blog.css";
import BlogData from "./services/blogdata";
import { MetaTags } from "react-meta-tags";

const Blog = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const Blogdata = BlogData.slice(0, 3);
  const Bloge = BlogData.slice(3, 9);
  const Blogge = BlogData.slice(2);

  console.log(Blogge);
  return (
    <>

          {/* ========= Seo ========== */}
          <MetaTags>
        <title>The Best Digital Marketing Agency In Andheri | Blog.</title>
        <meta
          name="description"
          content="The Best Digital Marketing Blogs Agency in Andheri Mumbai."
        />
        <meta
          property="og:title"
          content="The Best Digital Marketing Agency In Andheri, Mumbai."
        />
        <meta
          property="og:description"
          content="The Best Digital Marketing Blogs Agency in Andheri Mumbai."
        />
        <meta
          name="keywords"
          content="Digital Marketing Agency Blog In Andheri, Digital Marketing Blog Company In Andheri"
        />
        <link rel="canonical" href="https://skdm.in/digital-marketing-blog" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <div className="blogSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <div className="blogCard mainBlog">
                  <div className="row">
                    {Blogge.map((ele) => {
                      const { id, Slugs, BlogImage, BlogName, BlogDate } = ele;
                      return (
                        <Link to={`${Slugs}`}>
                          <div className="col-12 blogImg my-3">
                            <img
                              src={ele.BlogImage}
                              className="w-auto mw-100 rounded"
                              alt="Blog Services"
                            />
                            <h4 className="bold">
                              {ele.BlogName}
                              <Link to="/blogdetail">advantages.</Link>
                            </h4>
                            <p className="text-muted">
                              <strong>{ele.BlogDate}</strong>
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* ================================== Blog List 1 ============================ */}
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 d-block d-lg-none">
                  <hr></hr>
                </div>

                {Blogdata.map((ele) => {
                  const { id, Slugs, BlogImage, BlogName, BlogDate } = ele;
                  return (
                    <Link to={`${Slugs}`}>
                      <div className="col-12">
                        <div className="blogCard listBlog mt-3">
                          <div className="row">
                            <div className="col-4 blogImg">
                              <img
                                src={ele.BlogImage}
                                className="object-fit rounded"
                                alt="Blog Services"
                              />
                            </div>
                            <div className="col-8 blogBody">
                              <div className="row">
                                <div className="col-12">
                                  <div className="blogTitle">
                                    <h5 className="bold">
                                      <strong>{ele.BlogName}</strong>
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-12 d-flex justify-content-start">
                                  <div className="blogDeatils">
                                    <p className="text-muted">
                                      <strong>{ele.BlogDate}</strong>
                                    </p>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}

                {/*<NavLink to="#" className="col-12">
                  <div className="blogCard listBlog mt-3">
                    <div className="row">
                      <div className="col-4 blogImg">
                        <img
                          src="https://etimg.etb2bimg.com/photo/70562409.cms"
                          className="object-fit rounded"
                          alt="Blog Service"
                        />
                      </div>
                      <div className="col-8 blogBody">
                        <div className="row">
                          <div className="col-12">
                            <div className="blogTitle">
                              <h5 className="bold">
                                <strong>
                                  How to set up your own marketing company from
                                  scratch
                                </strong>
                              </h5>
                            </div>
                          </div>
                          <div className="col-12 d-flex justify-content-start">
                            <div className="blogDeatils">
                              <p className="text-muted">
                                <strong>20 Mar 2023</strong>
                              </p>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#" className="col-12">
                  <div className="blogCard listBlog mt-3">
                    <div className="row">
                      <div className="col-4 blogImg">
                        <img
                          src="https://communi8.com/wp-content/uploads/2022/09/fraudulent-marketing-agencies-Cover.jpg"
                          className="rounded"
                          alt="Blog Service"
                        />
                      </div>
                      <div className="col-8 blogBody">
                        <div className="row">
                          <div className="col-12">
                            <div className="blogTitle">
                              <h5 className="bold">
                                <strong>
                                  How to stay away from fraudulent marketing
                                  agencies
                                </strong>
                              </h5>
                            </div>
                          </div>
                          <div className="col-12 d-flex justify-content-start">
                            <div className="blogDeatils">
                              <p className="text-muted">
                                <strong>20 Mar 2023</strong>
                              </p>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </NavLink>*/}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================= Blog List 2 ======================= */}
      <div className="container">
        <div className="row">
          {/* <div className="col-sm-4 d-block d-lg-none">
            <hr></hr>
          </div> */}
          {Bloge.map((ele) => {
            const { id, Slugs, BlogImage, BlogName, BlogDate } = ele;
            return (
              <>
                <div className="col-sm-4 ">
                  <Link to={`${Slugs}`}>
                    <div className="blogCard cardBlog mt-3">
                      <div className="blogImg">
                        <img
                          src={BlogImage}
                          className="w-100 h-100 object-fit rounded"
                          alt="Blog Service"
                        />
                        <h5 className="bold">
                          <strong>{BlogName}</strong>
                        </h5>
                        <p className="text-muted">
                          <strong>{BlogDate}</strong>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            );
          })}

          {/* <NavLink to="#" className="col-lg-4 col-md-6 col-12">
            <div className="blogCard cardBlog mt-3">
              <div className="row">
                <div className="col-12 blogImg">
                  <img
                    src="https://worldfinancialreview.com/wp-content/uploads/2021/02/adults-analysis-brainstorming-1661004-696x522-1.jpg"
                    className="w-100 h-100 object-fit rounded"
                    alt="Blog Service"
                  />
                </div>
                <div className="col-12 blogBody mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="blogTitle">
                        <h5 className="bold">
                          <strong>
                    
                            How to keep a check of your competitors through
                            market research
                          </strong>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div className="blogDeatils">
                        <p className="text-muted">
                          <strong>20 Mar 2023</strong>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to="#" className="col-lg-4 col-md-6 col-12">
            <div className="blogCard cardBlog mt-3">
              <div className="row">
                <div className="col-12 blogImg">
                  <img
                    src="https://www.roionline.com/hubfs/Blog%20Photos/Concept%20of%20business%20as%20competition%20.jpg"
                    className="w-100 h-100 object-fit rounded"
                    alt="Blog Service"
                  />
                </div>
                <div className="col-12 blogBody mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="blogTitle">
                        <h5 className="bold">
                          <strong>
                    
                            How to stay on top of current marketing trends
                          </strong>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div className="blogDeatils">
                        <p className="text-muted">
                          <strong>20 Mar 2023</strong>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to="#" className="col-lg-4 col-md-6 col-12">
            <div className="blogCard cardBlog mt-3">
              <div className="row">
                <div className="col-12 blogImg">
                  <img
                    src="https://shawngraham.me/images/blog/how-to-increase-brand-awareness-small-business.jpg"
                    className="w-100 h-100 object-fit rounded"
                    alt="Blog Service"
                  />
                </div>
                <div className="col-12 blogBody mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="blogTitle">
                        <h5 className="bold">
                          <strong>
                    
                            How to create brand awareness for your Small
                            Business locally
                          </strong>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div className="blogDeatils">
                        <p className="text-muted">
                          <strong>20 Mar 2023</strong>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to="#" className="col-lg-4 col-md-6 col-12">
            <div className="blogCard cardBlog mt-3">
              <div className="row">
                <div className="col-12 blogImg">
                  <img
                    src="https://blog.ipleaders.in/wp-content/uploads/2021/08/What-is-False-Advertising.jpg"
                    className="w-100 object-fit rounded"
                    alt="Blog Service"
                  />
                </div>
                <div className="col-12 blogBody mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="blogTitle">
                        <h5 className="bold">
                          <strong>
                    
                            How to not get looted in the name of advertising
                          </strong>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div className="blogDeatils">
                        <p className="text-muted">
                          <strong>20 Mar 2023</strong>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
          <NavLink to="#" className="col-lg-4 col-md-6 col-12">
            <div className="blogCard cardBlog mt-3">
              <div className="row">
                <div className="col-12 blogImg">
                  <img
                    src="https://mydmi.imgix.net/cards/standard/Google_Ads_CARD.jpg?crop=edges&fit=crop&fm=jpg&h=1260&ixlib=php-3.3.1&q=40&w=2400&s=1cc4e30358032510cc0af165fbc31eb8"
                    className="w-100 h-100 object-fit rounded"
                    alt="Blog Service"
                  />
                </div>
                <div className="col-12 blogBody mt-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="blogTitle">
                        <h5 className="bold">
                          <strong>
                    
                            How to Improve Your Google Ads Quality Score Quickly
                          </strong>
                        </h5>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-start">
                      <div className="blogDeatils">
                        <p className="text-muted">
                          <strong>20 Mar 2023</strong>
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
  </NavLink> */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
