import React, { useLayoutEffect } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import "../../css/services.css";

import staticWebsite from "../../images/website_type/static.jpg";
import wordpress from "../../images/website_type/wordpress.jpg";
import dynamicWebsite from "../../images/website_type/dynamic.png";
import ecommerce from "../../images/website_type/ECommerce.png";
import whyUs from "../../images/website_type/whyUs.gif";
import like from "../../images/website_type/like.png";
import intern from "../../images/website_type/internet.png";
import reputation from "../../images/website_type/reputation.png";
import research from "../../images/website_type/research.png";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import TabList from "@mui/material/Tab";
import Portfolio from "../../Json/portfolio";

// ================= Technoloy Image ====================

import image1 from "../../images/workingTechnologu/1.png";
import image2 from "../../images/workingTechnologu/2.png";
import image3 from "../../images/workingTechnologu/3.png";
import image4 from "../../images/workingTechnologu/4.png";
import image5 from "../../images/workingTechnologu/5.png";
import image6 from "../../images/workingTechnologu/6.png";
import image7 from "../../images/workingTechnologu/7.png";
import image8 from "../../images/workingTechnologu/8.png";
import image9 from "../../images/workingTechnologu/9.png";
import image10 from "../../images/workingTechnologu/10.png";
import image11 from "../../images/workingTechnologu/11.png";
import image12 from "../../images/workingTechnologu/12.png";

// ========================== Step Image =========================

import coding from "../../images/stepsImage/coding.png";
import content from "../../images/stepsImage/content.png";
import liveAndMaintain from "../../images/stepsImage/liveAndMaintain.png";
import reasearchStep from "../../images/stepsImage/research.png";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import EnquiryForm from "../../form/enquiry_form";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

const WebDevelopment = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const [valueTab, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Best Website Development Company In Andheri | SKDM</title>
        <meta
          name="description"
          content="We are the best website development company in Andheri, Mumbai. We believe in providing clients with the best SEO friendly and Responsive website."
        />
        <meta
          property="og:title"
          content="Best Website Development Company In Andheri | SKDM "
        />
        <meta
          property="og:description"
          content="We are the best website development company in Andheri, Mumbai. We believe in providing clients with the best SEO friendly and Responsive website."
        />
        <meta
          name="keywords"
          content="Best Website Development Company In Andheri, Best Website Development Agency In Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/best-website-development-company-in-andheri"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />

      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-end order-2 order-md-1">
              <div className="pricingText">
                <h1>Best Website Development Company In Andheri</h1>
                <p>
                  Reach your audience on all devices with our full responsive
                  website development that adapts to any screen size and boosts
                  engagement.
                </p>
                <div className="buttonsList">
                  <button className="btn">
                    Pricing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img
                  src="https://cdni.iconscout.com/illustration/premium/thumb/web-development-programming-4315061-3610795.png"
                  alt="Website Development"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="typedong">
        <div className="titleSection">
          <span>VARIOUS WEBSITES</span>
          <h2>Types of Websites</h2>
          <p>
            At Shree Krishna Digital Marketing Agency, we specialize in creating
            all types of websites, tailored to our clients' specific needs and
            goals.
          </p>
        </div>
        <div className="container d-none d-md-flex">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className="typeMain">
                          {/* <div className="box">
                          <img src={staticWebsite} alt="Website Development"/>
                        </div> */}
                          <div className="head">
                            <h2>Static Website</h2>
                            <p>
                              Get online and get noticed with a static website -
                              the smart choice for small businesses.
                            </p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Divider />
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <div className="typeMain">
                          {/* <div className="box">
                          <img src={dynamicWebsite} alt="Dynamic Website" />
                        </div> */}
                          <div className="head">
                            <h2>Dynamic Website</h2>
                            <p>
                              Stand out with a dynamic website built for your
                              brand.
                            </p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Divider />
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <div className="typeMain">
                          {/* <div className="box">
                          <img src={ecommerce} alt="ecommerce Website "/>
                        </div> */}
                          <div className="head">
                            <h2>E-Commerce Website</h2>
                            <p>
                              Take your online presence to the next level with a
                              custom WordPress website.{" "}
                            </p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Divider />
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">
                        <div className="typeMain">
                          {/* <div className="box">
                          <img src={wordpress} alt="Wordpress Website" />
                        </div> */}
                          <div className="head">
                            <h2>Wordpress Website</h2>
                            <p>
                              Take your online presence to the next level with a
                              custom WordPress website.
                            </p>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </AnimationOnScroll>
              </Col>
              <Col sm={9}>
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="detailView">
                        <h3>Static Website</h3>
                        <img src={staticWebsite} alt="Static Website" />
                        <p>
                          A static website is a website that has fixed content
                          and displays the same information to every visitor.
                          They are perfect for businesses or individuals who
                          need a simple and cost-effective online presence. They
                          load quickly and are easy to maintain, making them
                          ideal for small businesses or startups on a tight
                          budget.
                        </p>
                        <p>
                          While static websites may not have the same level of
                          interactivity or dynamic content as other types of
                          websites, they are still highly effective in providing
                          information to potential customers. They are
                          relatively inexpensive to develop and maintain and can
                          be easily optimized for{" "}
                          <Link className="static_website" to="/seo-agency-in-andheri-mumbai">
                            search engines
                          </Link>{" "}
                          , ensuring that your business can be found online by
                          your target audience.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="detailView">
                        <h3>Dynamic Website</h3>
                        <img src={dynamicWebsite} alt="Dynamic Website" />
                        <p>
                          A dynamic website uses programming languages like
                          React.js, HTML, CSS, PHP, and Node.js to create a more
                          interactive and customized user experience. Unlike
                          static websites, dynamic sites can have content that
                          changes based on user input or other factors. With our
                          team of experts, we can help you build a dynamic
                          website that reflects your brand, engages your
                          audience, and drives conversions.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="detailView">
                        <h3>E-Commerce Website</h3>
                        <img src={ecommerce} alt="Ecommerce Website" />
                        <p>
                          An eCommerce website is designed to sell products or
                          services online. It involves building an online store
                          with features like product listings, shopping cart,
                          checkout process, and payment gateways. At Shree
                          Krishna Digital Marketing Agency, we specialize in
                          creating eCommerce websites that are tailored to your
                          business needs, ensuring a seamless customer
                          experience and driving sales. Our team of experts can
                          help you build a responsive, user-friendly, and secure
                          eCommerce website that meets all your requirements.
                        </p>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="detailView">
                        <h3>Wordpress Website</h3>
                        <img src={wordpress} alt="Wordpress Website" />
                        <p>
                          WordPress is a popular content management system that
                          powers millions of websites worldwide. It is a
                          versatile platform that allows you to build a website,
                          blog, or online store with ease. At Shree Krishna
                          Digital Marketing Agency, we specialize in creating
                          custom WordPress websites that are tailored to your
                          business needs. Our team of experts can help you build
                          a responsive, user-friendly, and secure WordPress
                          website that meets all your requirements.
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </AnimationOnScroll>
              </Col>
            </Row>
          </Tab.Container>
          {/*  */}
        </div>

        <div className="container d-block d-md-none">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
              style={{ margin: "10px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="typeMain">
                    {/* <div className="box">
                          <img src={staticWebsite} alt="Static Website" />
                        </div> */}
                    <div className="head">
                      <h2>Static Website</h2>
                      <p>
                        Get online and get noticed with a static website - the
                        smart choice for small businesses
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="detailView"
                    style={{ boxShadow: "unset", padding: "0" }}
                  >
                    <img src={staticWebsite} alt="Static Website" />
                    <p>
                      A static website is a website that has fixed content and
                      displays the same information to every visitor. They are
                      perfect for businesses or individuals who need a simple
                      and cost-effective online presence. They load quickly and
                      are easy to maintain, making them ideal for small
                      businesses or startups on a tight budget.
                    </p>
                    <p>
                      While static websites may not have the same level of
                      interactivity or dynamic content as other types of
                      websites, they are still highly effective in providing
                      information to potential customers. They are relatively
                      inexpensive to develop and maintain and can be easily
                      optimized for search engines, ensuring that your business
                      can be found online by your target audience.
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce={true}
          >
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
              style={{ margin: "10px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="typeMain">
                    {/* <div className="box">
                          <img src={staticWebsite} alt="Static Website" />
                        </div> */}
                    <div className="head">
                      <h2>Dynamic Website</h2>
                      <p>
                        Stand out with a dynamic website built for your brand.
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="detailView"
                    style={{ boxShadow: "unset", padding: "0" }}
                  >
                    <img src={dynamicWebsite} alt="Dynamic Website" />
                    <p>
                      A dynamic website uses programming languages like
                      React.js, HTML, CSS, PHP, and Node.js to create a more
                      interactive and customized user experience. Unlike static
                      websites, dynamic sites can have content that changes
                      based on user input or other factors. With our team of
                      experts, we can help you build a dynamic website that
                      reflects your brand, engages your audience, and drives
                      conversions.
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce={true}
          >
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChangeAccordion("panel3")}
              style={{ margin: "10px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="typeMain">
                    {/* <div className="box">
                          <img src={staticWebsite} alt="Static Website" />
                        </div> */}
                    <div className="head">
                      <h2>E-Commerce Website</h2>
                      <p>
                        Take your online presence to the next level with a
                        custom WordPress website.
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="detailView"
                    style={{ boxShadow: "unset", padding: "0" }}
                  >
                    <img src={ecommerce} alt="Ecommerce Website" />
                    <p>
                      An eCommerce website is designed to sell products or
                      services online. It involves building an online store with
                      features like product listings, shopping cart, checkout
                      process, and payment gateways. At Shree Krishna Digital
                      Marketing Agency, we specialize in creating eCommerce
                      websites that are tailored to your business needs,
                      ensuring a seamless customer experience and driving sales.
                      Our team of experts can help you build a responsive,
                      user-friendly, and secure eCommerce website that meets all
                      your requirements.
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AnimationOnScroll>

          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce={true}
          >
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChangeAccordion("panel4")}
              style={{ margin: "10px 0" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <div className="typeMain">
                    {/* <div className="box">
                          <img src={staticWebsite} alt="Static Website" />
                        </div> */}
                    <div className="head">
                      <h2>Wordpress Website</h2>
                      <p>
                        Take your online presence to the next level with a
                        custom WordPress website.
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    className="detailView"
                    style={{ boxShadow: "unset", padding: "0" }}
                  >
                    <img src={wordpress} alt="Wordpress Website" />
                    <p>
                      WordPress is a popular content management system that
                      powers millions of websites worldwide. It is a versatile
                      platform that allows you to build a website, blog, or
                      online store with ease. At Shree Krishna Digital Marketing
                      Agency, we specialize in creating custom WordPress
                      websites that are tailored to your business needs. Our
                      team of experts can help you build a responsive,
                      user-friendly, and secure WordPress website that meets all
                      your requirements.
                    </p>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </AnimationOnScroll>
        </div>
      </section>
      <section className="dexter">
        {/* <div class="container"> */}
        {/* <div className="titleSection">
          <span>REASON TO CHOOSE US</span>
          <h2>Why Choose Us</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
        </div> */}
        {/* </div> */}
        <div className="whychoose">
          <div className="row">
            <div className="col-lg-6">
              {/* <div class="typec">
              <h2>Why Choose Us</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et optio dicta quam hic aliquam a facilis laudantium ab quidem dolore.</p>
              </div> */}
            </div>
            <div className="col-lg-6">
              <div className="saver">
                <div>
                  <div className="typeChoose">
                    <div className="bc">
                      <img src={like} width="100%" height="100%" alt="Like" />
                    </div>
                    <div className="hc">
                      <h3>Best Website Development Agency</h3>
                      <p>
                        We are the best Website development Agency in Andheri
                        Mumbai. We elevate brands across all business
                        industries.
                      </p>
                    </div>
                  </div>
                </div>
                {/*---- for single col 12 ------*/}
                <div>
                  <div className="typeChoose">
                    <div className="bc">
                      <img
                        src={intern}
                        width="100%"
                        height="100%"
                        alt="Intern"
                      />
                    </div>
                    <div className="hc">
                      <h3>Amazing Designs</h3>
                      <p>
                        Our website design will help you stand out from the
                        crowd. Each client has a unique brand and requires a
                        unique graphic design approach.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="typeChoose">
                    <div className="bc">
                      <img
                        src={reputation}
                        width="100%"
                        height="100%"
                        alt="Reputation"
                      />
                    </div>
                    <div className="hc">
                      <h3>Full Satisfaction</h3>
                      <p>
                        We work with you to understand your online audience and
                        how to best meet their needs. You get one chance to
                        impress potential clients and first-look design is the
                        difference-maker.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="typeChoose">
                    <div className="bc">
                      <img
                        src={research}
                        width="100%"
                        height="100%"
                        alt="Research"
                      />
                    </div>
                    <div className="hc">
                      <h3>Competitor Analysis</h3>
                      <p>
                        Our websites not only offer stunning looks and unmatched
                        technology; they also outperform your competitors on all
                        major search engines. From e-commerce to membership
                        management, portfolio and interactive elements, we offer
                        everything you may need in website design.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whyCompanyDiffrent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="pricingText">
                  <div className="priImg">
                    <img src={whyUs} alt="Whyus" />
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="mic">
                  <h2>
                    Why are we different from Other Web Development Company?
                  </h2>
                  <p>
                    As the business leader in web design services in Andheri.
                    Our company offers high-quality premium website design
                    services that are customized according to a client’s
                    requirements. We work with clients closely to understand
                    each company’s online target audience and address their
                    specific needs. On every project, we engage multiple web
                    designers to give more design options. Having more options
                    available to you means that you can mix and match elements
                    from different concepts and incorporate them into the final
                    design. <Link className="internalLinking" to="/social-media-marketing-in-andheri-mumbai"> Also Know More About Social Media Marketing </Link>
                  </p>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className="ourPortfolio">
        <div className="container-fluid section bg_light serv_dtl_indstr_sec_wrap">
          <div className="titleSection">
            <span>OUR AWESOME PORTFOLIO</span>
            <h2>Completed Project</h2>
            <p>Projects Completed With Our Best Digital Marketing Plans.</p>
          </div>
          <div className="container px-0">
            <AnimationOnScroll
              animateIn="animate__fadeInDownBig"
              animateOnce={true}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  maxWidth: { lg: 900, xs: 320, sm: 480 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  <TabList label="Hospitality & Catering" />
                  <TabList label="Villa & Resorts" />
                  <TabList label="Doctors & Hospitals" />
                  <TabList label="Tours & Travels" />
                  <TabList label="Real Estate & Interior Design" />
                  <TabList label="Spa & Salon" />
                  <TabList label="Others" />
                </Tabs>
              </Box>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInUpBig"
              animateOnce={true}
            >
              <div className="portFolioDetails">
                {<Portfolio valueTab={valueTab} />}
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </section>

      {/* ================================================ Our Working Technology ========================================= */}
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <section
          className="ourWorkingTechnology"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="titleSection mb-5">
            <span>Technology</span>
            <h2>Our working technologies</h2>
            <p>
              Below Are The Modern Technologies We Use To Create A Powerful
              Website.
            </p>
          </div>
          <div className="container">
            <div className="row row-cols-md-4 row-cols-2">
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image1} alt="image" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image2} alt="web image" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image3} alt="webdevelopement image" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image4} alt="developement image" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image5} alt="image Developement" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image6} alt="image Design" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image7} />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image9} alt="image Animation" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image10} />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image11} alt="image"/>
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image12} alt="image" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll>

      {/* <section className="websitePricing">
        <div className="titleSection">
          <span>Our Pricing</span>
          <h2>OUR PACKAGES</h2>
          <p>
            Affordable packages for powerful results - unlock your business
            potential with Shree Krishna Digital Marketing Agency.
          </p>
        </div>

        <div className="container mt-0 mt-md-5 d-flex justify-content-center align-items-center">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col p-0 m-0">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Static Website</h3>
                    <p>For Startup Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          10,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>min start</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Single landing page.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>customized single page website design.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Linking your website On Google My Business .
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page integration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mailing System.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Free 2 Domain Mail.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hosting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>SSL Free.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mobile Friendly / Responsive.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Custom Content.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Google Mapping.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Whats aap Integration.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col p-0 m-0">
              <AnimationOnScroll
                animateIn="animate__fadeInDownBig"
                animateOnce={true}
              >
                <div className="pricingCard centerCard">
                  <div className="pricingTitle">
                    <h3>Ecommerce Website</h3>
                    <p>For Online Trading</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          60,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>min start</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple pages Six Pages.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Content Writing for website.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Detail Profile Creation.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Maintenance for 1 year.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Linking your website On Google My Business .
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mailing System.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hyperlink.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Free 5 Domain Mail.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hosting, SSL and Domain.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mobile Friendly / Responsive.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Customized banner.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Whats App Integration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Payment Integration.(User account created by client
                          only).
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Payment Integration.(User account created by client
                          only).
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>max product listing capacity 1000.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col p-0 m-0">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Dynamic Website</h3>
                    <p>For Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          20,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>min start</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple pages Five Pages.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Content Writing for website.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Detail Profile Creation.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Maintenance for 1 year.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Linking your website On Google My Business.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page integration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mailing System.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hyperlink.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Free 3 Domain Mail.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hosting, SSL and Domain.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mobile Friendly / Responsive .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>customized banner.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Google Mapping.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Whats App Integration .</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn mt-3">Book Now</button>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col p-0 m-0">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="pricingCard centerCard">
                  <div className="pricingTitle">
                    <h3>Wordpress Website</h3>
                    <p>For Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          15,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>min start</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple website Five pages.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Customized Banner Design.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Linking your website On Google My Business .
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page integration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mailing System .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Web mail (Business email id).</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hyperlink.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Free Domain Mail.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Hosting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>SSL Free.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Domain Registration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Mobile Friendly / Responsive.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Custom Content.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Google Mapping.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Whats aap Integration.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Extra Pages add on charges 1500/- per page .
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section> */}

      <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
        <section className="process d-none d-lg-block">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                <div className="titleSection">
                  <span>Working Process</span>
                  <h2>How It Works</h2>
                  <p>
                    We build powerful apps &amp; solutions for Trending
                    Industries.
                  </p>
                </div>
              </div>
            </div>
            <div className="boxes justify-content-between mb-5">
              <div className="single-box">
                <div className="wrap">
                  <div className="part-icon">
                    <span>
                      <img src={reasearchStep} alt="web Reasearch" />
                    </span>
                  </div>
                  <div className="part-txt">
                    <h3>Data Collecction & Research</h3>
                  </div>
                </div>
                <div className="arrow">
                  <img
                    src="https://athemeart.dev/html/zobita-html/assets/images/arrow.png"
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="single-box top">
                <div className="wrap">
                  <div className="part-txt">
                    <h3>Desinging & Implementation</h3>
                  </div>
                  <div className="part-icon">
                    <span>
                      <img src={coding} alt="Desinging & Implementation" />
                    </span>
                  </div>
                </div>
                <div className="arrow rotate">
                  <img
                    src="https://athemeart.dev/html/zobita-html/assets/images/arrow.png"
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="single-box">
                <div className="wrap">
                  <div className="part-icon">
                    <span>
                      <img src={content} alt="part-icon"  />
                    </span>
                  </div>
                  <div className="part-txt">
                    <h3>Content Writing</h3>
                  </div>
                </div>
                <div className="arrow">
                  <img
                    src="https://athemeart.dev/html/zobita-html/assets/images/arrow.png"
                    alt="arrow"
                  />
                </div>
              </div>
              <div className="single-box top">
                <div className="wrap">
                  <div className="part-txt">
                    <h3>Live & Maintanaince</h3>
                  </div>
                  <div className="part-icon">
                    <span>
                      <img src={liveAndMaintain} alt="Live & Maintanaince" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce={true}>
        <EnquiryForm
          toEmail="ansariarbaz254@gmail.com"
          leadSource="https://skdm.in"
          services="Website Development"
        />
      </AnimationOnScroll>

      <Footer />
    </>
  );
};

export default WebDevelopment;
