import React, { useLayoutEffect } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";

import whyChooceUs from "../../images/website_type/whyChooceUs.png";

import image1 from "../../images/socialIcon/1.png";
import image2 from "../../images/socialIcon/2.png";
import image3 from "../../images/socialIcon/3.png";
import image4 from "../../images/socialIcon/4.png";
import image5 from "../../images/socialIcon/5.png";
import image6 from "../../images/socialIcon/6.png";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import facebook from "../../images/socialIcon/facebook.png";
import instagram from "../../images/socialIcon/instagram.png";
import twitter from "../../images/socialIcon/twitter.png";
import linkedin from "../../images/socialIcon/linkedin.png";
import youtube from "../../images/socialIcon/youtube.png";
import pinterest from "../../images/socialIcon/pinterest.png";
import EnquiryForm from "../../form/enquiry_form";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

const SocialMarketing = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Social Media Marketing Company in Andheri, Mumbai | SKDM</title>
        <meta
          name="description"
          content="Top notch social media marketing company in Andheri, Mumbai. Our professional services can help enhance your on-line presence. Contact us now!"
        />
        <meta
          property="og:title"
          content="Social Media Marketing Company in Andheri, Mumbai | SKDM"
        />
        <meta
          property="og:description"
          content="Top notch social media marketing company in Andheri, Mumbai. Our professional services can help enhance your on-line presence. Contact us now!"
        />
        <meta
          name="keywords"
          content="Social Media Marketing Company in Andheri, Social Media Marketing Agency In Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/social-media-marketing-in-andheri-mumbai"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <div className="ditalMarktingSection">
        <section className="pricingBanner">
          <div className="container">
            <div className="row">
              <div class="col-md-6 d-flex justify-content-center align-items-end">
                <div class="pricingImg">
                  <img src="https://cdn.dribbble.com/users/2317423/screenshots/14354672/social_media_marketing_4x.jpg" alt="Social Media Management" />
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-center align-items-end">
                <div className="pricingText">
                  <h1>Social Media Marketing Company in Andheri, Mumbai</h1>
                  <p>
                  Are you ready to transform your business's online presence and skyrocket your brand awareness? Look no further! Shree Krishan Digital Marketing (SKDM) is here to empower your brand in the dynamic world of social media.
                  </p>
                  <div className="buttonsList">
                    <button className="btn">
                      Pricing{" "}
                      <i className="fa fa-angle-right" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatIsSocialMedia" style={{ background: "#eee" }}>
          <div className="titleSection">
            <span>About Social Media</span>

            <AnimationOnScroll
              animateIn="animate__fadeInDownBig"
              animateOnce={true}
            >
              <h2>What Is Social Media Marketing?</h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
            >
              <p>
                Social media marketing is an online marketing technique that
                uses various social media sites, such as Facebook, Twitter,
                LinkedIn, and Pinterest, to develop brand recognition, catch
                customer attention, and connect firms with a larger, more
                diversified target group. Social media marketing for small
                businesses and enterprises is an effective approach to reach
                your prospects where they spend their time online and increase
                brand engagement.
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
            >
              <p>
                A dynamic, data-driven social media marketing strategy can
                provide extraordinary results for your organisation and turn
                customers into brand evangelists. additional crucially, an
                inventive social media marketing approach effects your search
                engine optimisation (SEO) and digital marketing activities,
                resulting in additional leads and revenue streams for your
                company.
              </p>
            </AnimationOnScroll>
          </div>
        </section>

        {/* ======================================= Our Services ================================== */}

        <section className="card-category-2">
          <div className="titleSection">
            <span>OUR SERVICES</span>
            <h2>Social Media Services</h2>
            <p>
              We provide customised social media solutions that generate
              measurable outcomes and drive business success using our
              data-driven approach and industry experience.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        {/* <span class="card-caption">Image Caption</span> */}
                        <img src={facebook} alt="Facebook Logo"/>
                      </div>
                      <span className="card-title">Facebook</span>
                      <div className="card-text">
                        <p>
                          Facebook is a powerful social media platform that
                          enables businesses to connect with their audience,
                          build brand awareness, and drive sales.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInUpBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        {/* <span class="card-caption">Image Caption</span> */}
                        <img src={twitter} alt="Twitter Logo"/>
                      </div>
                      <span className="card-title">Twitter</span>
                      <div className="card-text">
                        <p>
                          Twitter is a versatile social media platform that
                          allows businesses to reach a broad audience, engage
                          with customers, and build a strong brand presence.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        <img src={instagram} alt="Instagram Logo" />
                      </div>
                      <span className="card-title">Instagram </span>
                      <div className="card-text">
                        <p>
                          A visual-centric social media platform that provides
                          businesses with the opportunity to showcase their
                          brand, engage with followers, and drive sales.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        {/* <span class="card-caption">Image Caption</span> */}
                        <img src={linkedin} alt="Linkedin Logo" />
                      </div>
                      <span className="card-title">Linkedin</span>
                      <div className="card-text">
                        <p>
                          A professional social network that enables businesses
                          to connect with other professionals, showcase their
                          brand, and recruit top talent.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        {/* <span class="card-caption">Image Caption</span> */}
                        <img src={youtube} alt="YouTube Logo" />
                      </div>
                      <span className="card-title">You Tube</span>
                      <div className="card-text">
                        <p>
                          A video-sharing platform that offers businesses a
                          powerful way to create engaging content, build brand
                          awareness, and reach a vast audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>

              <div className="col-12 col-sm-6 col-md-4 mt-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="img-card iCard-style2">
                    <div className="card-content">
                      <div className="card-image">
                        {/* <span class="card-caption">Image Caption</span> */}
                        <img src={pinterest} alt="Pinterest Logo"/>
                      </div>
                      <span className="card-title">Pinterest</span>
                      <div className="card-text">
                        <p>
                          A visually-driven social media platform that allows
                          businesses to showcase their products or services,
                          drive traffic, and connect with a highly engaged
                          audience.
                        </p>
                      </div>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>

        {/* ========================================= Why Choose Us =================================== */}

        <section className="xavier">
          <div className="container">
            <div className="titleSection">
              <span>REASON TO CHOOSE US</span>
              <h2>Why Choose Us</h2>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
            </div>
            {/* <div class="dmwhychoose"> */}
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 m-auto">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="digichoose">
                    <h2>
                      Why choose SKDM as your Social Media Marketing provider{" "}
                    </h2>
                    <p>
                    At SKDM, we understand that social media is not just a platform; it's a gateway to connect, engage, and influence your target audience. Our expert team of digital marketing enthusiasts thrives on creating innovative strategies that cater to your unique business goals. As the leading social media marketing company in Andheri, Mumbai, we offer a blend of creativity, data-driven insights, and industry expertise to ensure your brand stands out in the digital realm.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="digimar">
                    <img className="w-100" src="https://seotask.in/wp-content/uploads/2020/05/corporate-social-media-management-services-768x384.png" alt="Social Media Management Agency" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="talha" style={{ backgroundColor: "#fff" }}>
          <div className="titleSection">
            <span>GROWTH </span>
            <h2>Business Growth</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
          </div>
          <div className="container dmbg">
            {/* <div class="dmwhychoose"> */}
            <div className="row">
              <div className="col-lg-6 m-auto order-2 order-md-1">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="digibusigrowth">
                    <h2>
                      How does Social Media services drive business growth.
                    </h2>
                    <p>
                      Social media drives business growth by providing
                      businesses with a highly visible, accessible way to
                      connect with their audience. By building a strong social
                      media presence and engaging with customers through content
                      and conversation, businesses can build brand awareness,
                      establish themselves as thought leaders in their industry,
                      and drive traffic and sales. Social media advertising also
                      offers powerful targeting capabilities, allowing
                      businesses to reach specific audiences with personalized
                      messaging that resonates with them. <Link className="internalLinking" to="/seo-agency-in-andheri-mumbai"> Also Know More About Our Search Engine Optimization </Link>
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-6">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="bgser">
                    <img src="https://www.apac-insider.com/wp-content/uploads/2022/02/Business-Growth-1.jpg" alt="Social Media Growth" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="websitePricing">
          <div className="titleSection">
            <span>Our Pricing</span>
            <h2>Our packages</h2>
            <p>
              Affordable packages for powerful results - unlock your business
              potential with Shree Krishna Digital Marketing Agency.
            </p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInDownBig"
            animateOnce={true}
          >
            <div className="container mt-5 d-flex justify-content-center align-items-center">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col p-0 m-0">
                  <div className="pricingCard">
                    <div className="pricingTitle">
                      <h3>Basic Package</h3>
                      <p>For Startup Business</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            7000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Any Two Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            10 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Confirmation before Posting.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Keywords Hashtag / Description.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way..
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis.</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="pricingCard centerCard">
                    <div className="pricingTitle">
                      <h3>Elite Package</h3>
                      <p>For Enterprise Business</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            25000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Three Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            12 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Confirmation before Posting.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Keywords Hashtag / Description.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>100 leads gurantee.</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="pricingCard">
                    <div className="pricingTitle">
                      <h3>Pro Package</h3>
                      <p>For Growing Buisness</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            15000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Any Two Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            10 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Generating 50 lead per month Gurantee</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </section> */}

        <AnimationOnScroll
          animateIn="animate__fadeInLeftBig"
          animateOnce={true}
        >
          <EnquiryForm
            toEmail="shreekrishanadigital09@gmail.com"
            leadSource="https://skdm.in"
            services="Social Media Marketing"
          />
        </AnimationOnScroll>
      </div>
      <Footer />
    </>
  );
};

export default SocialMarketing;
