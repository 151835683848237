
////////////// Door Images //////////////////


// ------------------conferences--------------------
import religious1 from "../images/ganpati.jpeg";
import off1 from "../images/off1.jpeg"
import off2 from "../images/off2.jpeg"
import off3 from "../images/off3.jpeg"
import off4 from "../images/off4.jpeg"
import off5 from "../images/off5.jpeg"
import off6 from "../images/off6.jpeg"
import off7 from "../images/off7.jpeg"

import g1 from "../images/g1.jpeg"
import g2 from "../images/g2.jpeg"
import g3 from "../images/g3.jpeg"
import g4 from "../images/g4.jpeg"
import g5 from "../images/g5.jpeg"

import t1 from "../images/t1.jpeg"
import t2 from "../images/t2.jpeg"
import t3 from "../images/t3.jpeg"
import t4 from "../images/t4.jpeg"
import t5 from "../images/t5.jpeg"



import pr1 from "../images/pr1.jpeg"
import pr2 from "../images/pr2.jpeg"
import pr3 from "../images/pr3.jpeg"
import pr4 from "../images/pr4.jpeg"
import pr5 from "../images/pr5.jpeg"
import pr6 from "../images/pr6.jpeg"
import pr7 from "../images/pr7.jpeg"


import b1 from "../images/b1.jpeg"
import b2 from "../images/b2.jpeg"
import b3 from "../images/b3.jpeg"
import b4 from "../images/b4.jpeg"
import b5 from "../images/b5.jpeg"
import b6 from "../images/b6.jpeg"
import b7 from "../images/b7.jpeg"
import b8 from "../images/b8.jpeg"
import b9 from "../images/b9.jpeg"
import b10 from "../images/b10.jpeg"
const GalleryData = [
  // {
  //   id: 1,
  //   Category:"Events",
  //   CategoryName:"Workshop",
  //   CImage:religious1,
  // },
  {
    id: 1,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:religious1,
  },
  {
    id: 1,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:religious1,
  },
 

  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"AGM's",
  //   CImage:sitti,
  // },
  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"Product Launches",
  //   CImage:sitting,
  // },
  // {
  //   id: 1,
  //   Category:"Corporate Functions",
  //   CategoryName:"Press Conference",
  //   CImage:sitting,
  // },
  {
    id: 2,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b1,
  },
  {
    id:3,
    Category:"Office",
    CategoryName:"Office",
    CImage:off1,
  },
  {
    id: 20,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr1,
  
  },
  {
    id:4,
    Category:"Office",
    CategoryName:"Office",
    CImage:off2,
  },
  {
    id:5,
    Category:"Office",
    CategoryName:"Office",
    CImage:off3,
  },
  {
    id:6,
    Category:"Offices",
    CategoryName:"Office",
    CImage:off4,
  },
  {
    id:7,
    Category:"Office",
    CategoryName:"Office",
    CImage:off5,
  },
  {
    id:8,
    Category:"Office",
    CategoryName:"Office",
    CImage:off6,
  },
  {
    id:9,
    Category:"Office",
    CategoryName:"Office",
    CImage:off7,
  },

  {
    id: 10,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:g1,
  },
  {
    id: 11,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:g2,
  },
  {
    id: 12,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:g3,
  },
  {
    id: 13,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:g4,
  },
  {
    id: 14,
    Category:"Festivals",
    CategoryName:"Conferences",
    CImage:g5,
  },
  {
    id: 15,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:t1,
  },
  {
    id: 16,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:t2,
  },
  {
    id: 17,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:t3,
  },
  {
    id: 18,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:t4,
  },
  {
    id: 19,
    Category:"Tours",
    CategoryName:"Seminars",
    CImage:t5,
  },
 
  {
    id: 21,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr2,
  
  },
  {
    id: 22,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr3,
  
  },
  {
    id: 23,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr4,
  
  },
  {
    id: 24,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr5,
  
  },
  {
    id: 25,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr6,
  
  },
  
  {
    id: 26,
    Category:"PR",
    CategoryName:"Corporate Meetings",
    CImage:pr7,
  
  },
  {
    id: 27,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b2,

  },
  {
    id: 28,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b3,
  },
  {
    id: 29,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b5,
  },
  {
    id: 30,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b6,
  },
  {
    id: 31,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b7,
  },
  {
    id: 32,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b8,
  },
  {
    id: 33,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b9,
  },
  {
    id: 34,
    Category:"Birthday Celebrations",
    CategoryName:"Office",
    CImage:b10,
  },


];

export default GalleryData;

