import React from "react";

// ===================== Hospitality & Catering ==================
import catering1 from "../images/hospatilaty & Catering/1.png";
import catering2 from "../images/hospatilaty & Catering/2.png";
import catering3 from "../images/hospatilaty & Catering/3.png";
import catering4 from "../images/hospatilaty & Catering/4.png";
import catering5 from "../images/hospatilaty & Catering/5.png";
import catering6 from "../images/hospatilaty & Catering/6.png";
import catering7 from "../images/hospatilaty & Catering/7.png";
import catering8 from "../images/hospatilaty & Catering/8.png";

// ================= Villa & Resourt =====================

import villa1 from "../images/villa & resort/1.png";
import villa2 from "../images/villa & resort/2.png";
import villa3 from "../images/villa & resort/3.png";
import villa4 from "../images/villa & resort/4.png";
import villa5 from "../images/villa & resort/5.png";
import villa6 from "../images/villa & resort/6.png";
import villa7 from "../images/villa & resort/7.png";
import villa8 from "../images/villa & resort/8.png";
import villa9 from "../images/villa & resort/9.png";
import villa10 from "../images/villa & resort/10.png";
import villa11 from "../images/villa & resort/11.png";

// ========================= Doctors ====================
import doctors1 from "../images/doctor & hospital/1.png";
import doctors2 from "../images/doctor & hospital/2.png";
import doctors3 from "../images/doctor & hospital/3.png";
import doctors4 from "../images/doctor & hospital/4.png";
import doctors5 from "../images/doctor & hospital/5.png";

// ===================== Tours and Travels ==================

import tours1 from "../images/tours & Travels/1.png";
import tours2 from "../images/tours & Travels/2.png";
import tours3 from "../images/tours & Travels/3.png";
import tours4 from "../images/tours & Travels/4.png";

// ====================== Spa & Saloon =====================

import spa1 from "../images/spa & salon/1.png";
import spa2 from "../images/spa & salon/2.png";
import spa3 from "../images/spa & salon/3.png";
import spa4 from "../images/spa & salon/4.png";
import spa5 from "../images/spa & salon/5.png";
import spa6 from "../images/spa & salon/6.png";
import spa7 from "../images/spa & salon/7.png";
import spa8 from "../images/spa & salon/8.png";

// ====================== Real Estate & Interior Design =====================

import real1 from "../images/realestate/1.png";
import real2 from "../images/realestate/2.png";

// ====================== Others =====================

import other1 from "../images/other/1.png";

  const PortfolioData = [
    {
      id: 0,
      name: "Hospitality & Catering",
      images: [
        {
          image: catering1,
          link: "https://dlakhanihospitality.com/",
        },
        {
          image: catering2,
          link: "http://tfctiffincentre.in/",
        },
        {
          image: catering3,
          link: "http://asmihospitality.com/",
        },
        {
          image: catering4,
          link: "https://aanganclassichall.com/",
        },
        {
          image: catering5,
          link: "http://maladdegrande.in/",
        },
        {
          image: catering6,
          link: "http://komalbanquet.com/",
        },
        {
          image: catering7,
          link: "http://vaishnavbanquet.com/",
        },
        {
          image: catering8,
          link: "https://sampoornabhog.com/",
        },
      ],
    },
    {
      id: 1,
      name: "Villa & Resourts",
      images: [
        {
          image: villa1,
          link: "http://canvasvilla.co.in/",
        },
        {
          image: villa2,
          link: "http://chintamanihabitat.in/",
        },
        {
          image: villa3,
          link: "http://eerasagroresort.com/",
        },
        {
          image: villa4,
          link: "https://estays.in/",
        },
        {
          image: villa5,
          link: "https://stayhippo.com/",
        },
        {
          image: villa6,
          link: "https://sainaresort.com/",
        },
        {
          image: villa7,
          link: "http://fonsecasbeachresorts.in/",
        },
        {
          image: villa8,
          link: "http://vaityaqua.in/",
        },
        {
          image: villa9,
          link: "#",
        },
        {
          image: villa10,
          link: "#3",
        },
        {
          image: villa11,
          link: "http://chandanefarms.in/",
        },
      ],
    },
    {
      id: 2,
      name: "Doctors & Hospitals",
      images: [
        {
          image: doctors1,
        },
        {
          image: doctors2,
        },
        {
          image: doctors3,
        },
        {
          image: doctors4,
        },
        {
          image: doctors5,
        },
      ],
    },
    {
      id: 3,
      name: "Tours & Travels",
      images: [
        {
          image: tours1,
        },
        {
          image: tours2,
        },
        {
          image: tours3,
        },
        {
          image: tours4,
        },
      ],
    },
    {
      id: 4,
      name: "Real Estate & Interior Design",
      images: [
        {
          image: real1,
        },
        {
          image: real2,
        },
      ],
    },
    {
      id: 5,
      name: "Spa & Saloon",
      images: [
        {
          image: spa1,
        },
        {
          image: spa2,
        },
        {
          image: spa3,
        },
        {
          image: spa4,
        },
        {
          image: spa5,
        },
        {
          image: spa6,
        },
        {
          image: spa7,
        },
        {
          image: spa8,
        },
      ],
    },
    {
      id: 6,
      name: "Others",
      images: [
        {
          image: other1,
        },
      ],
    },
  ];

export default PortfolioData;
