import React, { useLayoutEffect } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";

import creative from "../../images/GraphicDesign/creative-thinking.png";
import logo from "../../images/GraphicDesign/logo-design.png";
import visitCard from "../../images/GraphicDesign/visiting-card.png";
import websiteBanner from "../../images/GraphicDesign/advertising.png";
import brachur from "../../images/GraphicDesign/brochure.png";
import phamplates from "../../images/GraphicDesign/flyers.png";
import creativeReels from "../../images/GraphicDesign/video.png";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import TabList from "@mui/material/Tab";
import Portfolio from "../../Json/portfolio";

import image1 from "../../images/GraphicDesign/1.png";
import image2 from "../../images/GraphicDesign/2.png";
import image3 from "../../images/GraphicDesign/3.png";
import image4 from "../../images/GraphicDesign/4.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import EnquiryForm from "../../form/enquiry_form";

import {
  MdCurrencyRupee,
  MdDesignServices,
  MdInsertPageBreak,
  MdVideoCameraBack,
  MdVideoLibrary,
} from "react-icons/md";
import { RiPagesFill, RiVideoFill } from "react-icons/ri";
import { IoLogoBitbucket, IoLogoBuffer } from "react-icons/io";
import { IoLogoEdge } from "react-icons/io5";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

const GraphicDesign = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  const [valueTab, setValueTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Best Graphic Design Agency in Mumbai | SKDM</title>
        <meta
          name="description"
          content="Experience artistic brilliance and visual storytelling with the best graphic design agency in Mumbai. Elevate your brand's aesthetics with us."
        />
        <meta
          property="og:title"
          content="Best Graphic Design Agency in Mumbai | SKDM"
        />
        <meta
          property="og:description"
          content="Experience artistic brilliance and visual storytelling with the best graphic design agency in Mumbai. Elevate your brand's aesthetics with us."
        />
        <meta
          name="keywords"
          content="Best Graphic Design Company in Andheri, Best Graphic Design Agency in Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/best-graphic-design-agency-in-mumbai"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img src="https://unblast.com/wp-content/uploads/2020/05/Graphic-Design-Vector-Illustration.jpg" alt="
Best Graphic Design Agency in Mumbai" />
              </div>
            </div>

            <div className="col-md-6 d-flex justify-content-center align-items-end">
              <div className="pricingText">
                <h1>Best Graphic Design Agency in Mumbai</h1>
                <p>
                  Shree Krishna digitals marketing agency is working as a
                  graphic design agency in Mumbai.we are one of the preferred
                  design agencies in Mumbai owing to our quality-standards and
                  great experience. We have been working over bulk design
                  production requirements as well as creative design
                  requirements.
                </p>
                <div className="buttonsList">
                  <button className="btn">
                    Pricing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
        <section className="graphicDesign">
          <div className="titleSection">
            <span>Graphic Design</span>
            <h2>Importance of graphic design for business</h2>
            <p>
              Delivering a solid and concise message is imperative to
              establishing a connection with your target audience. Generating
              compelling visuals, focused ads, and specific landing pages is
              vital to showcasing your brand. Visuals are an important part of
              your online presence.
            </p>
          </div>
        </section>
      </AnimationOnScroll>

      <section id="team" className="graphiceSection">
        <div className="titleSection">
          <span>GRAPHIC DESIGN SERVICES</span>
          <h2>Types Of Works</h2>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing hic.</p>
        </div>
        <div className="container d-flex justify-content-center">
          {/* <h5 class="section-title h1 mb-5">Types Of Works</h5> */}
          <div className="row d-flex justify-content-center">
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="image-flip">
                  <div className="mainflip flip-0">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          {/* <p><img class=" img-fluid" src="assets/images/GraphicDesign/creative-tools.png" alt="card image"></p> */}
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={creative}
                              alt="Creative Design logo"
                            />
                          </div>
                          <h4 className="card-title">Creative Design</h4>
                          <p className="card-text">
                            At our Graphic Design Agency, take pride in creating
                            design that not only stands out in a feed but is
                            also proven to drive results.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  onstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          {/* <p><img class=" img-fluid" src="assets/images/GraphicDesign/design.png" alt="card image"></p> */}
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={logo}
                              alt="Logo Design logo"
                            />
                          </div>
                          <h4 className="card-title">Logo Design </h4>
                          <p className="card-text">
                            Your logo, in most cases, is the first impression
                            your company makes on potential customers. You don’t
                            get a second{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={visitCard}
                              alt="Visiting Card logo"
                            />
                          </div>
                          <h4 className="card-title">Visiting Card</h4>
                          <p className="card-text">
                            Business cards act as pocket sized billboards. When
                            designed well, business cards leave a lasting first
                            impression.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInDownBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={websiteBanner}
                              alt="Website Banner Design logo"
                            />
                          </div>
                          <h4 className="card-title">Website Banner Design</h4>
                          <p className="card-text">
                            Good website banners design ideas attract customers,
                            bad one’s sway them away. Which one would you like
                            to own? Fueled
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={brachur}
                              alt="Brochure Design logo"
                            />
                          </div>
                          <h4 className="card-title">Brochure Design</h4>
                          <p className="card-text">
                            An excellent brochure design can help make a lasting
                            impression on potential customers. With the right
                            combination of professional
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={phamplates}
                              alt="Pamphlets logo"
                            />
                          </div>
                          <h4 className="card-title">Pamphlets</h4>
                          <p className="card-text">
                            Are you struggling to level up your business on a
                            wide scale with more sales and revenue? Actually
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-3 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={creativeReels}
                              alt="Creative Reel And Videos logo"
                            />
                          </div>
                          <h4 className="card-title">
                            Creative reel and videos
                          </h4>
                          <p className="card-text">
                            We create branded and commercial video content for
                            corporate marketing, advertising, and social media
                            use, short films
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ourWorkingTechnology"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="titleSection mb-5">
          <span>Technology</span>
          <h2>Our working technologies</h2>
          <p>
            Below Are The Modern Technologies We Use To Create A Powerful
            Website.
          </p>
        </div>
        <AnimationOnScroll
          animateIn="animate__fadeInDownBig"
          animateOnce={true}
        >
          <div className="container">
            <div className="row row-cols-md-4 row-cols-2">
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image1} alt="photoshop Icon" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image2} alt="adobe illustrator Icon" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image3} alt="Coreldraw Icon" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image4} alt="premiere pro Logo"/>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </section>
      <section className="ourvision">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="visioncontent">
                  <h5>Why Choose Us</h5>
                  <h2>Why choose Skdm for graphic design</h2>
                  <p>
                    Our people are our core strength in delivering a stress-free
                    and superior quality working experience to our partners’.
                    Whether you want us to develop a design from scratch or
                    adapt your design and produce the rest of the pages based on
                    it, we have the team strength and the right talent to get
                    your work done seamlessly. <Link className="internalLinking" to="/ppc-management-company-in-andheri"> Also Know More About PPC Marketing Agency.</Link>
                  </p>
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div className="visionlist">
                  <ul>
                    <li>Receive remarkable results</li>
                    <li> Help them build their business</li>
                    <li> Digital consultants</li>
                  </ul>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="whyChooseImg">
                  <img
                    style={{ width: "100%" }}
                    src="https://img.freepik.com/premium-vector/graphic-designer-with-creative-inspiration-work-space-concept-illustration_270158-306.jpg" alt="Best Graphic Design Agency in Mumbai"
                  />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className="websitePricing">
        <div className="titleSection">
          <span>Our Pricing</span>
          <h2>Our Package</h2>
          <p>
            Affordable packages for powerful results - unlock your business
            potential with Shree Krishna Digital Marketing Agency.
          </p>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
          <div className="container mt-5 d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col p-0 m-0">
                <div className="pricingListCard">
                  <div className="row">
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <MdVideoCameraBack
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              40 sec Video creative{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>1800/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <MdVideoLibrary
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              1 Min Video creative{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>2400/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <RiVideoFill
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              1.30 Min Video creative{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>3000/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <MdDesignServices
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              Bill board or sign board banner design{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>7000/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <IoLogoBitbucket
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              Logo design with 3 options{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>8000/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <IoLogoEdge
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              Logo design with 5 options{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>12000/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <IoLogoBuffer
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              3d Logo design with 3 options{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>20000/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4  mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <RiPagesFill
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              Brochure design A4 size{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>2500/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="pricingCardData">
                        <div className="row">
                          <div className="col-3 d-flex justify-content-center align-items-start">
                            <div className="cardImgs">
                              <MdInsertPageBreak
                                style={{
                                  fontSize: "3.5rem",
                                  color: "var(---thirdColor)",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-9 mt-2">
                            <h4
                              style={{
                                fontSize: "1.1rem",
                                fontFamily: "var(---robotoFont)",
                                color: "var(---primeColor)",
                                fontWeight: "500",
                              }}
                            >
                              Pamphlet design{" "}
                            </h4>
                            <p
                              className="d-flex align-items-center justify-content-start"
                              style={{ color: "#808080", fontSize: "1rem" }}
                            >
                              <MdCurrencyRupee />
                              <span>2200/-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col p-0 m-0">
              <div className="pricingCard">
                <div className="pricingTitle">
                  <h3>Static Website</h3>
                  <p>For Startup Business</p>
                  <div className="pricingPrice">
                    <h3>
                      <i className="fa fa-inr" aria-hidden="true" />
                      <span>19999</span>
                    </h3>
                  </div>
                </div>
                <div className="pricingPoint">
                  <ul>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                  </ul>
                </div>
                <div className="pricingButton">
                  <button className="btn">Book Now</button>
                </div>
              </div>
            </div> */}
              {/* <div className="col p-0 m-0">
              <div className="pricingCard centerCard">
                <div className="pricingTitle">
                  <h3>Dynamic Website</h3>
                  <p>For Enterprise Business</p>
                  <div className="pricingPrice">
                    <h3>
                      <i className="fa fa-inr" aria-hidden="true" />
                      <span>19999</span>
                    </h3>
                  </div>
                </div>
                <div className="pricingPoint">
                  <ul>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                  </ul>
                </div>
                <div className="pricingButton">
                  <button className="btn">Book Now</button>
                </div>
              </div>
            </div> */}
              {/* <div className="col p-0 m-0">
              <div className="pricingCard">
                <div className="pricingTitle">
                  <h3>Ecommerce Website</h3>
                  <p>For Online Trading</p>
                  <div className="pricingPrice">
                    <h3>
                      <i className="fa fa-inr" aria-hidden="true" />
                      <span>19999</span>
                    </h3>
                  </div>
                </div>
                <div className="pricingPoint">
                  <ul>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                    <li>
                      <i className="material-symbols-outlined">done</i>{" "}
                      <span>Lorem ipsum dolor sit amet.</span>{" "}
                    </li>
                  </ul>
                </div>
                <div className="pricingButton">
                  <button className="btn">Book Now</button>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </AnimationOnScroll>
      </section>
      <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce={true}>
        <EnquiryForm
          toEmail="ansariarbaz254@gmail.com"
          leadSource="https://skdm.in"
          services="Graphic Design"
        />
      </AnimationOnScroll>

      <Footer />
    </>
  );
};

export default GraphicDesign;
