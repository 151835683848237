import React, { useLayoutEffect } from "react";
import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";

import "../css/Contact.css";
import { NavLink } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

const ContactUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Contact Us | Digital Marketing Company In Andheri</title>
        <meta
          name="description"
          content="Address - Andheri West, Mumbai, Maharashtra 400053, Ph No- 70213 90953. Email Id- shreekrishnadigital09@gmail.com."
        />
        <meta
          property="og:title"
          content="Contact Us | Digital Marketing Company In Andheri"
        />
        <meta
          property="og:description"
          content="Address - Andheri West, Mumbai, Maharashtra 400053, Ph No- 70213 90953. Email Id- shreekrishnadigital09@gmail.com."
        />
        <meta
          name="keywords"
          content="Contact Us Digital Marketing Agency In Andheri, Contact Us Digital Marketing Company In Andheri"
        />
        <link rel="canonical" href="https://skdm.in/contact-us-digital-marketing" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <div className="aboutUs" id="aboutUsSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 d-flex align-items-center mt-3 mt-md-0 order-2 order-md-1">
              <div className="aboutUsTitle">
                <h1>Contact Us</h1>
                <p>
                  Contact with shree krishna digital marketing and give us
                  feedback so we can improve our service as much as best.
                </p>
                {/* <a href="#packageList">
                  <button className="btn packageButton">View More</button>
                </a> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="aboutImg">
                <img
                  src="https://img.freepik.com/free-vector/contact-us-concept-illustration_114360-2299.jpg?w=2000"
                  alt="Best Digital Marketing Agency In Andheri"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contactUsDetail">
        <div className="container">
          <div class="footer-cta pt-5 pb-5" style={{ border: "unset" }}>
            <div class="row">
              <div class="col-xl-3 col-lg-6 col-md-6 mb-4 mb-xl-0 d-flex justify-content-center mb-30">
                <div class="single-cta contactCard">
                  <i class="fa fa-map-marker"></i>
                  <div class="cta-text">
                    <h4>Find us</h4>
                    <NavLink
                      to="https://goo.gl/maps/HdSr4MNd6hz7ipug8"
                      style={{ color: "#111" }}
                    >
                      {" "}
                      Andheri West, Mumbai, Maharashtra 400053
                    </NavLink>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-4 mb-xl-0 d-flex justify-content-center mb-30">
                <div class="single-cta contactCard">
                  <i class="fa fa-phone"></i>
                  <div class="cta-text">
                    <h4>Call us</h4>
                    <NavLink to="tel:+91 70213 90953" style={{ color: "#111" }}>
                      +91 70213 90953
                    </NavLink>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-4 mb-xl-0 d-flex justify-content-center mb-30">
                <div class="single-cta contactCard">
                  <i class="fa fa-envelope-open"></i>
                  <div class="cta-text">
                    <h4>Mail us</h4>
                    {/* <NavLink to="mailto:info@skdm.in" style={{ color: "#111" }}>
                      info@skdm.in
                    </NavLink> */}
                    <br></br>
                    <NavLink
                      to="mailto:shreekrishnadigital09@gmail.com"
                      style={{ color: "#111" }}
                    >
                      shreekrishnadigital09<br/>@gmail.com
                    </NavLink>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 mb-4 mb-xl-0 d-flex justify-content-center mb-30">
                <div class="single-cta contactCard">
                  <i class="fa fa-envelope-open"></i>
                  <div class="cta-text">
                    <h4>Social Media</h4>
                    <div
                      class="navbar-socialLinks d-none d-md-block"
                      id="socialId"
                    >
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/shree.krishnadigitalmarketing/">
                            <i class="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/shree.krishnadigitalmarketing/">
                            <i class="fa fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/?originalSubdomain=in">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://wa.me/+917021390953">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="enquiryUs">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <h3 className="heading enquiryTitle mb-4">
                    Let's talk about everything!
                  </h3>
                  <img
                    src="https://preview.colorlib.com/theme/bootstrap/contact-form-16/images/undraw-contact.svg"
                    alt="Best Digital Marketing Agency In Andheri "
                    className="img-fluid"
                    data-pagespeed-url-hash={827056895}
                  />
                </div>
                <div className="col-md-6">
                  <form
                    className="mb-5"
                    method="post"
                    id="contactForm"
                    name="contactForm"
                    noValidate="novalidate"
                  >
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Your name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          cols={30}
                          rows={7}
                          placeholder="Write your message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <button type="submit" className="btn enquiryButton">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15077.660553274367!2d72.80918342908898!3d19.13329709287954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c946e4282bd9%3A0x2781c048e4376933!2sShree%20Krishna%20Digital%20Marketing%20Services!5e0!3m2!1sen!2sin!4v1681465395452!5m2!1sen!2sin"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <Footer />
    </>
  );
};

export default ContactUs;
