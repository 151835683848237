import React, { useLayoutEffect, useState } from "react";
import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";

import "../css/Career.css";

import BeatifullOffice from "../images/career/BeatifullOffice.png";
import EasyLocation from "../images/career/EasyLocation.png";
import FriendlyEnviorment from "../images/career/FriendlyEnviorment.png";
import GreatAppraisal from "../images/career/GreatAppraisal.png";
import GreatCoWorkers from "../images/career/GreatCoWorkers.png";
import SkillDevelopment from "../images/career/SkillDevelopment.png";

import cv from "../images/career/cv.png";
import support from "../images/career/support.png";
import risk from "../images/career/risk.png";
import interview from "../images/career/interview.png";

import { FiSearch } from "react-icons/fi";

import vancancy from "../Json/vancancy.json";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import EnquiryForm from "../form/enquiry_form";
import { MetaTags } from "react-meta-tags";

const Career = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [serviceTitle, setServiceTitl] = useState();
  const [enquired, setEnquired] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (idValue) => {
    setShow(true);
    setId(idValue);
    setEnquired(false);
  };

  const enquiredModal = (title) => {
    setShow(true);
    setServiceTitl(title);
    setEnquired(true);
  };
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Join The Best Digital Marketing Company In Andheri | Careers.</title>
        <meta
          name="description"
          content="Unleash your potential at the finest digital marketing company in Andheri. Elevate your career with us and shape the future of marketing."
        />
        <meta
          property="og:title"
          content="Join The Best Digital Marketing Company In Andheri | Careers."
        />
        <meta
          property="og:description"
          content="Unleash your potential at the finest digital marketing company in Andheri. Elevate your career with us and shape the future of marketing."
        />
        <meta
          name="keywords"
          content="Join The Best Digital Marketing Agency In Mumbai Careers, Join The Best Digital Marketing Company In Andheri Careers."
        />
        <link rel="canonical" href="https://skdm.in/careers-in-digital-marketing-andheri" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img src="https://img.freepik.com/premium-vector/graph-profit-income-growth-teamwork-achieving-results-successful-business-concept_143808-842.jpg" alt="graph-profit" />
              </div>
            </div>

            <div className="col-md-6 d-flex justify-content-center align-items-end">
              <div className="pricingText">
                <h1 style={{ fontSize: "3rem" }}>Career Growth</h1>
                <p>
                  Digital marketing has emerged as a rapidly growing field in
                  recent years, and Shree Krishna Digital Marketing can provide
                  excellent career growth opportunities for aspiring
                  professionals. Here are some reasons why a career in digital
                  marketing with Shree Krishna can be a great choice for your
                  professional growth
                </p>
                <div className="buttonsList">
                  <button className="btn">Know More </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutCreereSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="facilityBoxList">
                <div className="row">
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox topActive">
                      <img src={BeatifullOffice} alt="Beatifull Office" />
                      <h5>Beatifull Office</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox">
                      <img src={GreatCoWorkers} alt="GreatCo Workers" />
                      <h5>Great Co-Workers</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox topActive">
                      <img src={EasyLocation} alt="Easy Location" />
                      <h5>Easy Location</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox">
                      <img src={SkillDevelopment} alt="Skill Development" />
                      <h5>Skill Development</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox topActive">
                      <img src={FriendlyEnviorment} alt="Friendly Enviorment" />
                      <h5>Friendly Enviorment</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                  <div className="col-6 mb-4 d-flex justify-content-center align-items-center">
                    <div className="facilityBox">
                      <img src={GreatAppraisal} alt="Great Appraisal" />
                      <h5>Great Appraisal</h5>
                      {/* <p>
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit.
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="aboutCareer">
                <h2>
                  Join Our Digital Marketing Teams <span>At SKDM</span>
                </h2>
                <p>
                  At Shree Krishna Digital Marketing, we provide career
                  opportunities for our employees while helping them grow their
                  skills. We offer a range of job services including web
                  development, graphic design, video editing, and social media
                  management.
                </p>
                <p>
                  Our employees have the opportunity to work on cutting-edge
                  projects and receive ongoing training and development to reach
                  their full potential. Our culture and environment are
                  collaborative and supportive, and we are dedicated to helping
                  our team members achieve their goals. Explore our current job
                  openings to take the next step in your career with Shree
                  Krishna Digital Marketing.
                </p>
                <button>Know More</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="jobVacancy">
        <div className="container">
          {/* <div className="jobSearchDesign">
            <div className="jobSectionTitle">
              <h5>Job Opening</h5>
              <h5>
                <span>{vancancy.length}</span> Available Vacancy
              </h5>
            </div>
            <div className="searchBoc mb-4">
              <input
                type="text"
                name=""
                placeholder="Search Here"
                id=""
                className="form-control"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <FiSearch />
            </div>

            <div className="jobFiltering">
              <div className="row">
                <div className="col-md-3 mb-2">
                  <select name="" id="" className="form-select">
                    <option disabled selected>
                      Department
                    </option>
                    <option value="Website Development">
                      Website Development
                    </option>
                    <option value="Social Media Marketing">
                      Social Media Marketing
                    </option>
                    <option value="SEO">SEO</option>
                    <option value="Graphic Design">Graphic Design</option>
                  
                    <option value="Paid Ads">PPC</option>
                  </select>
                </div>
                <div className="col-md-3 mb-2">
                  <select name="" id="" className="form-select">
                    <option disabled selected>
                      Job Type
                    </option>
                    <option value="Full Time">Full Time</option>
                    <option value="Part Time">Part Time</option>
                    <option value="Internship">Internship</option>
                  </select>
                </div>
                <div className="col-md-3 mb-2">
                  <select name="" id="" className="form-select">
                    <option disabled selected>
                      Job Experience
                    </option>
                    <option value="Experiance">Experience</option>
                    <option value="Fresher">Fresher</option>
                  </select>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </div> */}

          <div className="vacancyList mt-5">
            <div className="row">
              {vancancy.map((value, i) => (
                <div className="col-lg-3 col-md-6 col-12 mb-3">
                  <div className="vacancyCard">
                    <h5>{value.title}</h5>
                    <p>{value.experiance}</p>
                    <div className="buttonList">
                      <button
                        className="btn mb-3 mb-md-0"
                        onClick={() => handleShow(i)}
                      >
                    Requirements
                      </button>
                      <button
                        className="btn"
                        onClick={() => enquiredModal(value.title)}
                      >
                        Enquire
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="titleSection">
          <span>Our Process</span>
          <h2>Learn Our Requirment Process</h2>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="facilityBox">
                <img src={cv} alt="CV Submission" />
                <h5>CV Submission</h5>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="facilityBox">
                <img src={support} alt="Phone Interview" />
                <h5>Phone Interview</h5>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="facilityBox">
                <img src={risk} alt="" />
                <h5>Skill Assessment</h5>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-3">
              <div className="facilityBox">
                <img src={interview} alt="Final Interview" />
                <h5>Final Interview</h5>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />

      {/* ============================== Job Discription ============================== */}

      <Modal show={show} onHide={handleClose} centered>
        {enquired ? (
          ""
        ) : (
          <Modal.Header closeButton>
            <Modal.Title>Role</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {enquired ? (
            <EnquiryForm
              toEmail="ansariarbaz254@gmail.com"
              leadSource="https://skdm.in"
              services={serviceTitle}
              formType="job"
            />
          ) : (
            vancancy
              .filter((value, index) => index === id)
              .map((valueData) => (
                <>
                  <h4>{valueData.title}</h4>
                  <span>{valueData.experiance}</span>
                  {/* <span className="mx-3">{valueData.jobType}</span> */}
                  <br/><br/>
                  <span className="mx-3">{valueData.role}</span>
                  <h5 className="mt-3">Qualification</h5>
                  {valueData.JobDiscription.map((valueDisc) => (
                    <ul className="mx-3">
                      <li>{valueDisc}</li>
                    </ul>
                  ))}
                </>
              ))
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Career;
