import React, { useLayoutEffect } from "react";

import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";

import Facebook from "../../images/paidAds/facebook.png";
import Instagram from "../../images/paidAds/instagram.png";
import addwords from "../../images/paidAds/adwords.png";

import blueTick from "../../images/paidAds/bluetick.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import EnquiryForm from "../../form/enquiry_form";

import socialMediaAds from "../../images/socialMediaAds.jpg";
import googleAds from "../../images/googleAds.jpg";
import { MetaTags } from "react-meta-tags";

const PaidAds = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Pay Per Click (PPC) Management Company in Andheri | SKDM</title>
        <meta
          name="description"
          content="Best PPC management company in Andheri. We offer the best in PPC services which drive targeted traffic and maximize conversions. Get In Touch Now!"
        />
        <meta
          property="og:title"
          content="Pay Per Click (PPC) Management Company in Andheri | SKDM"
        />
        <meta
          property="og:description"
          content="Best PPC management company in Andheri. We offer the best in PPC services which drive targeted traffic and maximize conversions. Get In Touch Now!"
        />
        <meta
          name="keywords"
          content="Pay Per Click (PPC) Management Company in Andheri, Pay Per Click (PPC) Management Agency in Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/ppc-management-company-in-andheri"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-end order-2 order-md-1">
              <div className="pricingText">
                <h1>PPC Marketing Company In Andheri</h1>
                <p>
                Shree Krishna Digital Marketing stands as the premier PPC marketing company in Andheri, setting an unrivaled standard for online advertising excellence. With a profound understanding of pay-per-click strategies, our team excels in crafting targeted and result-oriented campaigns that drive exceptional outcomes. As the go-to destination for businesses seeking impactful PPC solutions, we combine strategic insights with creative innovation to maximize click-through rates, conversions, and overall ROI. Our commitment to staying at the forefront of PPC trends and our relentless pursuit of excellence make us the undeniable choice for businesses in Andheri aiming to dominate the digital advertising landscape.
                </p>
                <div className="buttonsList">
                  <button className="btn">
                    Pricing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img src="https://media.istockphoto.com/id/1300261982/vector/ppc-illustration-vector-concept.jpg?s=612x612&w=0&k=20&c=CPX0TdByy7JS8G3ockPLKS1QOCBfedgHqAKjW8WU7gA=" alt="PPC Marketing Company In Andheri" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="team" className="paidServices">
        <div className="titleSection">
          <span>Paid Ads Services</span>
          <h2>Paid Marketing Solutions we offer</h2>
          <p>
            We professionally manage your Paid Advertising by making every click
            count. We are experts in driving targeted traffic to boost online
            sales and revenue..
          </p>
        </div>
        <div className="container d-flex justify-content-center">
          {/* <h5 class="section-title h1 mb-5">Types Of Works</h5> */}
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-md-4 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="image-flip">
                  <div className="mainflip flip-0">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          {/* <p><img class=" img-fluid" src="assets/images/GraphicDesign/creative-tools.png" alt="card image"></p> */}
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={addwords}
                              alt="Google Adwords"
                            />
                          </div>
                          <h4 className="card-title">Google Adwords</h4>
                          <p className="card-text">
                            Appear on Google’s search results page through
                            custom campaigns.We provide Adwords management which
                            is purposeful and ROI-Driven.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-4 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  onstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          {/* <p><img class=" img-fluid" src="assets/images/GraphicDesign/design.png" alt="card image"></p> */}
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={Facebook}
                              alt="Facebook Ads"
                            />
                          </div>
                          <h4 className="card-title">Facebook Ads</h4>
                          <p className="card-text">
                            Allow your brand to run incredibly targeted
                            advertising on Facebook.We conduct ad campaigns that
                            are designed to highlight your Brand.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-4 mb-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div
                  className="image-flip"
                  ontouchstart="this.classList.toggle('hover');"
                >
                  <div className="mainflip">
                    <div className="frontside">
                      <div className="ServiceCards">
                        <div className="card-body text-center">
                          <div className="typeImage">
                            <img
                              className=" img-fluid"
                              src={Instagram}
                              alt="Instagram Ads"
                            />
                          </div>
                          <h4 className="card-title">Instagram Ads</h4>
                          <p className="card-text">
                            Expand your reach by targeting topics, location and
                            platform.Get started with Quora Ads for your
                            business to insert and update leads efficiently.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className="section new_section perilous_business">
        <div className="container">
          <h3>Why are Paid Ads Services beneficial for your business?</h3>
          <p>
            Paid ads services are beneficial for several reasons. Our team uses
            the latest strategies to deliver you its most benefits in your
            unique budget.
          </p>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-md-0 mb-3 order-2 order-md-1">
              <ul>
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <li className="">
                    <figure>
                      <img src={blueTick} className="img-fluid" alt="Paid Ads Services" />
                    </figure>
                    <div className="topic_content">
                      <h5>Cost-effective</h5>
                      <p>
                        PPC is the most cost-effective way to advertise your
                        business. You pay when the user clicks your ad. Plus,
                        PPC ads are vell targetted. This means with PPC services
                        you can spend money only when there is a serious scope
                        of making a sale.
                      </p>
                    </div>
                  </li>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <li className="">
                    <figure>
                      <img src={blueTick} className="img-fluid" alt="Cost effective" />
                    </figure>
                    <div className="topic_content">
                      <h5>Boost revenue</h5>
                      <p>
                        Paid Advertising Services focuses on increasing traffic
                        to your website, the effectiveness of landing pages, and
                        ad budget. The combination of these factor boosts your
                        business revenue quickly.
                      </p>
                    </div>
                  </li>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <li className="">
                    <figure>
                      <img src={blueTick} className="img-fluid" alt="Boost revenue" />
                    </figure>
                    <div className="topic_content">
                      <h5>Well targetted</h5>
                      <p>
                        PPC ads are hyper-targeted but it requires an expert
                        team to use targeting options to reach your targetted
                        audience. The ads are prepared to target audience with
                        specific content.
                      </p>
                    </div>
                  </li>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <li className="">
                    <figure>
                      <img src={blueTick} className="img-fluid" alt="Well targetted" />
                    </figure>
                    <div className="topic_content">
                      <h5>Deliver fast results</h5>
                      <p>
                        PPC is known to deliver quick results. Unlike SEO and
                        email marketing where it takes time to reap results PPC
                        works quickly. Constant monitoring of your PPC campaign
                        ensures it works as expected.
                      </p>
                    </div>
                  </li>
                </AnimationOnScroll>
              </ul>
            </div>
            <div className="col-12 col-md-6 mb-md-0 mt-3">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <img
                  src="https://lirp.cdn-website.com/97dbf77f/dms3rep/multi/opt/Google+Ads+Management-1920w.png"
                  className="img-fluid"
                  alt="Deliver fast results"
                />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section style={{ background: "#eee" }}>
        <div className="container">
          <div className="titleSection">
            <h2>
              {" "}
              Revolutionize Your Marketing Strategy with Shree Krishna Digital
              Marketing - From Social Media Ads to Proven Results, We've Got You
              Covered!
            </h2>{" "}
          </div>
          <img
            style={{
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              display: "flex",
            }}
            src={socialMediaAds}
            alt="Affordable packages"
          />
        </div>
      </section>

      <section className="websitePricing">
        <div className="titleSection">
          <span>Our Pricing</span>
          <h2>OUR Social Media PACKAGE</h2>
          <p>
            Affordable packages for powerful results - unlock your business
            potential with Shree Krishna Digital Marketing Agency.
          </p>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
          <div className="container mt-5 d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Basic Plan </h3>
                    <p>For Startup Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          10,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Creatives Making- Image/ Carousal / Video Creatives.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headlines.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Leads Formate- Form filling/ WhatsApp/ Call.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Locations Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>WhatsApp Business Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisations .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 100.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>5k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard centerCard">
                  <div className="pricingTitle">
                    <h3>Premium Plan</h3>
                    <p>For Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          40,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Creatives Making- Image/ Carousal / Video Creatives.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headlines.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Leads Formate- Form filling/ WhatsApp/ Call.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Locations Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>WhatsApp Business Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisations .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 400.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>5k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Classic Plan</h3>
                    <p>For Growing Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          25,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Creatives Making- Image/ Carousal / Video Creatives.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headlines.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Leads Formate- Form filling/ WhatsApp/ Call.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Locations Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Social Media Page Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>WhatsApp Business Linking.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisations .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 250.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>5k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </section>
      <section>
        <div className="container">
          <div className="titleSection">
            <h2>
              {" "}
              Drive Your Business Forward with Shree Krishna Digital Marketing -
              Unleash the Power of Google Ads for Incredible Results!
            </h2>{" "}
          </div>
          <img
            style={{
              width: "100%",
              maxWidth: "1100px",
              margin: "auto",
              display: "flex",
            }}
            src={googleAds}
            alt="Google Ads"
          />
        </div>
      </section>
      <section className="googleAds" style={{ background: "#eee" }}>
        <div className="titleSection">
          <span>Our Pricing</span>
          <h2>OUR Google Ads PACKAGE</h2>
          <p>
            Affordable packages for powerful results - unlock your business
            potential with Shree Krishna Digital Marketing Agency.
          </p>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
          <div className="container mt-5 d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Basic Plan </h3>
                    <p>For Startup Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          20,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description- multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headline - Multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads Format- Form Filling, Calling.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Location Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Keyword Search.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Negative keywords .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple Ad sets.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ad group.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisation.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 100.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>7k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard centerCard">
                  <div className="pricingTitle">
                    <h3>Premium Plan</h3>
                    <p>For Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          45,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description- multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headline - Multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads Format- Form Filling, Calling.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Location Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Keyword Search.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Negative keywords .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple Ad sets.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ad group.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisation.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 150.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>7k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Classic Plan</h3>
                    <p>For Growing Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          30,000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Description- multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Headline - Multiple.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads Format- Form Filling, Calling.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Location Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Audience Targeting.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Keyword Search.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Negative keywords .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Multiple Ad sets.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ad group.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Budgeting .</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Ads Optimisation.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Leads - 250.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>7k extra service charge.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </section>

      <section>
        <div className="container">
          <div className="perfect-box bg-transparent pt-0 pb-0">
            <div className="titleSection">
              <span>We Do</span>
              <h2>Challenges we solve</h2>
            </div>
            <div className="row justify-content-center">
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 wow fadeIn"
                data-wow-delay="300ms"
              >
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="perfect-box-item">
                    <div className="icon">
                      <img
                        width={96}
                        height={96}
                        src="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073120/Adopt-right-platform.png"
                        className="attachment-full size-full"
                        alt="Paid Ads"
                        loading="lazy"
                        srcSet="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073120/Adopt-right-platform.png 96w, https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073120/Adopt-right-platform-62x62.png 62w"
                        sizes="(max-width: 96px) 100vw, 96px"
                      />
                    </div>
                    <h5>Adopt right platform</h5>
                    <p>
                      At KrishaWeb, we can choose the right platform to showcase
                      the content/stories based on the type of your target
                      customers to maximize your gain through the process of
                      paid advertising.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 wow fadeIn"
                data-wow-delay="300ms"
              >
                <AnimationOnScroll
                  animateIn="animate__fadeInUpBig"
                  animateOnce={true}
                >
                  <div className="perfect-box-item">
                    <div className="icon">
                      <img
                        width={96}
                        height={96}
                        src="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073145/Improved-web-traffic-and-clicks.png"
                        className="attachment-full size-full"
                        alt="Paid Ads"
                        loading="lazy"
                        srcSet="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073145/Improved-web-traffic-and-clicks.png 96w, https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073145/Improved-web-traffic-and-clicks-62x62.png 62w"
                        sizes="(max-width: 96px) 100vw, 96px"
                      />
                    </div>
                    <h5>Improved web traffic and clicks</h5>
                    <p>
                      We can improve your brand’s recognition with help from
                      Google AdWords and Facebook Advertising. These efforts can
                      improve your website’s online traffic and click-through
                      rate.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 wow fadeIn"
                data-wow-delay="300ms"
              >
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="perfect-box-item">
                    <div className="icon">
                      <img
                        width={96}
                        height={96}
                        src="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073157/Drive-genuine-leads.png"
                        className="attachment-full size-full"
                        alt="Paid Ads"
                        loading="lazy"
                        srcSet="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073157/Drive-genuine-leads.png 96w, https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073157/Drive-genuine-leads-62x62.png 62w"
                        sizes="(max-width: 96px) 100vw, 96px"
                      />
                    </div>
                    <h5>Drive genuine leads</h5>
                    <p>
                      KrishaWeb professionals use ad management and campaign
                      management to improve awareness of any brand. We promote
                      the content across different marketing channels to
                      generate genuine leads.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 wow fadeIn"
                data-wow-delay="300ms"
              >
                <AnimationOnScroll
                  animateIn="animate__fadeInDownBig"
                  animateOnce={true}
                >
                  <div className="perfect-box-item">
                    <div className="icon">
                      <img
                        width={96}
                        height={96}
                        src="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073210/Highest-conversion-rates.png"
                        className="attachment-full size-full"
                        alt="Paid Ads"
                        loading="lazy"
                        srcSet="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073210/Highest-conversion-rates.png 96w, https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073210/Highest-conversion-rates-62x62.png 62w"
                        sizes="(max-width: 96px) 100vw, 96px"
                      />
                    </div>
                    <h5>Highest conversion rates</h5>
                    <p>
                      We have the expertise to create compelling, relevant and
                      clickable PPC ads for any brand or business. With
                      dedicated efforts, we can increase conversion rates and
                      let businesses reap the benefits.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div
                className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4 wow fadeIn"
                data-wow-delay="300ms"
              >
                <AnimationOnScroll
                  animateIn="animate__fadeInUpBig"
                  animateOnce={true}
                >
                  <div className="perfect-box-item">
                    <div className="icon">
                      <img
                        width={96}
                        height={96}
                        src="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073226/Reduce-cost-per-acquisition.png"
                        className="attachment-full size-full"
                        alt="Paid Ads"
                        loading="lazy"
                        srcSet="https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073226/Reduce-cost-per-acquisition.png 96w, https://d1hdtc0tbqeghx.cloudfront.net/wp-content/uploads/2018/10/11073226/Reduce-cost-per-acquisition-62x62.png 62w"
                        sizes="(max-width: 96px) 100vw, 96px"
                      />
                    </div>
                    <h5>Reduce cost per acquisition </h5>
                    <p>
                      The marketing budget is a huge concern for all businesses.
                      The experts of KrishaWeb can greatly reduce the Cost per
                      Acquisition and increase ROI through profitable paid
                      campaigns.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce={true}>
        <EnquiryForm
          toEmail="ansariarbaz254@gmail.com"
          leadSource="https://skdm.in"
          services="Paid Ads"
        />
      </AnimationOnScroll>
      <Footer />
    </>
  );
};

export default PaidAds;
