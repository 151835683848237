import React from 'react';
import "../../css/ErrorPage.css";
import { Link } from 'react-router-dom';
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";
import ErrorIcon from "../../images/404error.png"

const ErrorPage = () => {
  return (
    <>
    <Header />
    <section className="Error_page">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="error_title">
                    <div className="errorlogo">
                        <img src="https://github.com/SKDM1990/SKDMFolder/blob/main/images/footerLogo.png?raw=true"
                alt="company logo" />
                    </div>
                    <div className="ErrorDesc">
                        <h2><span>404</span> That's An Error</h2>
                        <p>The requested URL /badpage was not found on this server. That's all we know. Please Go To Home Page</p>
                        <Link to="/">Home</Link>
                    </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="ErrorRight">
                    <div className="errorImg">
                        <img src={ErrorIcon} alt="404 Error Digital Marketing" />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default ErrorPage;
