import React, { useLayoutEffect } from "react";

const ComingSoon = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });
    return(
    <div style={{width:"100%", height:"100vh", background:"#F5F7F9", display:"flex", justifyContent:"center", alignItems:"center"}}>
    <img src="https://cdn.dribbble.com/users/1812146/screenshots/6968859/media/4dbc28dbc8ba5743377283381fd83286.png?compress=1&resize=1000x750&vertical=top" style={{width:"100%", maxWidth:"700px", margin:"auto"}} alt="Best Digital Marketing Agency In Andheri" />
    </div>
    )
}

export default ComingSoon;