import React, { useLayoutEffect, useState } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import BlogData from "./blogdata";
import { useLocation } from "react-router-dom";

const Blogsdetail = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  // ===============
  const search = useLocation();
  const Path = search.pathname
  // console.log(id);

  // document.write(id)
  // const { id } = useParams("")
  // console.log(id)

  const [edata, setEdata] = useState(BlogData);
  // const [Fdata,setFdata] = useState([])

  const EventFdata = edata.find((e) => e.Slugs  == Path);
  return (
    <>
      <Header />
      <div className="blogSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
                <div className="blogCard mainBlog">
                  <div className="row">
                    <div className="col-12 blogImg my-3">
                      <img
                        src={EventFdata.BlogImage}
                        className="w-auto mw-100 rounded"
                        alt="Blog Details"
                      />
                    </div>
                    <div className="col-12 blogBody">
                      <div className="row">
                        <div className="col-12">
                          <div className="blogTitle">
                            <h4 className="bold">{EventFdata.BlogName}</h4>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-start">
                          <div className="blogDeatils">
                            <p className="text-muted">
                              <strong>{EventFdata.BlogDate}</strong>
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              {EventFdata.BlogDescription1}
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              {EventFdata.BlogDescription2}
                            </p>
                            <p style={{ textAlign: "justify" }}>
                              {EventFdata.BlogDescription3}
                            </p>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            {/* ================================== Blog List 1 ============================ */}
            <div className="col-lg-4">
              <div className="row">
                <div className="col-12 d-block d-lg-none">
                  <hr></hr>
                </div>
                {BlogData.map((ele) => {
                  const { id, Slugs, BlogImage, BlogName, BlogDate } = ele;
                  return (
                    <Link to={`${Slugs}`}>
                      <div to="#" className="col-12">
                        <div className="blogCard listBlog mt-3">
                          <div className="row">
                            <div className="col-4 blogImg">
                              <img
                                src={BlogImage}
                                className="object-fit rounded"
                                alt="Blogs"
                              />
                            </div>
                            <div className="col-8 blogBody">
                              <div className="row">
                                <div className="col-12">
                                  <div className="blogTitle">
                                    <h5 className="bold">
                                      <strong> {BlogName}</strong>
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-12 d-flex justify-content-start">
                                  <div className="blogDeatils">
                                    <p className="text-muted">
                                      <strong>{BlogDate}</strong>
                                    </p>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ============================= Blog List 2 ======================= */}

      <Footer />
    </>
  );
};

export default Blogsdetail;
