import React, { useLayoutEffect } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";

import seoAbout from "../../images/seo/services_img.png";
import mediaIcon from "../../images/seo/mediaIcon.png";
import infoicon from "../../images/seo/infoIcon.png";

import penalty from "../../images/seo/penalty.png";
import seo from "../../images/seo/seo.png";
import mediaStrategy from "../../images/seo/strategy.png";
import realTime from "../../images/seo/realTime.png";
import onlineMedia from "../../images/seo/onlineMedia.png";
import report from "../../images/seo/resporting.png";

import seoBGImg from "../../images/seo/services_img.png";

import iphone from "../../images/seo/iphone-1.png";
import chek1 from "../../images/seo/chek.png";
import chek2 from "../../images/seo/chek.png";
import chek3 from "../../images/seo/chek.png";
import chek4 from "../../images/seo/chek.png";
import chek5 from "../../images/seo/chek.png";
import chek6 from "../../images/seo/chek.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import EnquiryForm from "../../form/enquiry_form";
import { MetaTags } from "react-meta-tags";
import {AiOutlineArrowRight} from "react-icons/ai"
import { Link } from "react-router-dom";

const SEO = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
         {/* ========= Seo ========== */}
         <MetaTags>
        <title>SEO Agency in Andheri Mumbai | SKDM</title>
        <meta
          name="description"
          content="Unlock unparalleled online visibility with the foremost SEO agency in Andheri Mumbai. Transform your digital landscape today."
        />
        <meta
          property="og:title"
          content="SEO Agency in Andheri Mumbai | SKDM"
        />
        <meta
          property="og:description"
          content="Unlock unparalleled online visibility with the foremost SEO agency in Andheri Mumbai. Transform your digital landscape today."
        />
        <meta
          name="keywords"
          content="SEO Company in Andheri, SEO Agency In Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/seo-agency-in-andheri-mumbai"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center align-items-end order-2 order-md-1">
              <div className="pricingText">
                <h1>SEO Agency in Andheri Mumbai</h1>
                <p>
                  Boost your online visibility and attract more potential
                  customers with our expert SEO services. Our team at Shree
                  Krishna Digital Marketing uses cutting-edge strategies and
                  techniques to help your website rank higher in search engine
                  results pages. Let us help you achieve your online goals with
                  our customized SEO solutions.
                </p>
                <div className="buttonsList">
                  <button className="btn">
                    Pricing{" "}
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img src="https://static.vecteezy.com/system/resources/previews/002/136/499/original/seo-analysis-concept-with-people-work-on-screen-search-engine-optimization-marketing-and-strategies-illustration-vector.jpg" alt="SEO Agency in Andheri Mumbai" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutSeo">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="bestimg">
                  <img src={seoAbout} alt="SEO Agency" />
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-6">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="headtitle">
                  <h5>Best for you</h5>
                  <h2>Boosts Your Website Traffic!</h2>
                  <p>
                    Shree Krishna Digital Marketing's SEO services can help
                    boost your website's traffic and increase your online
                    visibility. Our team of experts uses the latest techniques
                    to optimize your website for search engines and attract more
                    potential customers to your business
                  </p>
                </div>
              </AnimationOnScroll>

              <div className="bestcont-wrap">
                <div className="row">
                  <div className="col-md-6">
                    <AnimationOnScroll
                      animateIn="animate__fadeInUpBig"
                      animateOnce={true}
                    >
                      <div className="bestcontent1">
                        <div className="besthead1">
                          <img src={mediaIcon} alt="Media Promotion Icon" />
                        </div>
                        <h3>Media Promotion</h3>
                        <p>
                          Our Media Promotion SEO services are designed to boost
                          your online visibility and increase your website's
                          search engine ranking.
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                  <div className="col-md-6">
                    <AnimationOnScroll
                      animateIn="animate__fadeInDownBig"
                      animateOnce={true}
                    >
                      <div className="bestcontent1">
                        <div className="besthead1">
                          <img src={infoicon} alt="InfoGraphics Icon" />
                        </div>
                        <h3>Infographics Content</h3>
                        <p>
                          Transform complex information into stunning visuals
                          with our Infographics Content services, and engage
                          your audience like never before!
                        </p>
                      </div>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ourvision">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="visioncontent">
                  <h5>Our Vision</h5>
                  <h2>
                    We Provide
                    <Link className="internalLinking" to="/blog"> Best SEO Services</Link>
                  </h2>
                  <p>
                    At Shree Krishna Digital Marketing, we provide the best SEO
                    services to help your website rank higher on search engines
                    and drive more traffic. Our team of experts uses a
                    data-driven approach and the latest tools to optimize your
                    website and improve its online visibility. With our
                    customized SEO strategies, we help businesses of all sizes
                    achieve their online goals and stay ahead of the
                    competition. Trust us to provide you with the best SEO
                    services that will take your website to new heights.
                  </p>
                </div>
              </AnimationOnScroll>
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div className="visionlist">
                  <ul>
                    <li>Receive remarkable results</li>
                    <li> Help them build their business</li>
                    <li> Digital consultants</li>
                  </ul>
                </div>
                <div className="mt-5 Iternal_linking">
                <Link className="internalLinking" to="/best-graphic-design-agency-in-mumbai"> <i><AiOutlineArrowRight/></i> Also Know More About Our Graphic Design </Link>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="visionimg">
                  <img src="https://img.freepik.com/free-vector/seo-concept-illustration_114360-5766.jpg?w=2000" alt="Seo" />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </section>

      <section className="seoserv-sections">
        <div className="container">
          <div className="titleSection">
            <span>Our Services</span>
            <h2>How We Can Help?</h2>
          </div>
          <div className="seoserv-wrap">
            <div className="row">
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={seo} alt="ranking Icon" />
                    </div>
                    <h3>Search Engine Optimization</h3>
                    <p>
                      Boost your website's online visibility with our expert SEO
                      services! Our team utilizes the latest techniques and
                      strategies to drive organic traffic to your website,
                      helping you to rank higher in search engine results and
                      grow your business.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInUpBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={mediaStrategy} alt="Media Strategy Icon" />
                    </div>
                    <h3>Social Media Strategy</h3>
                    <p>
                      A well-planned social media strategy can enhance brand
                      visibility, build engagement, and drive website traffic.
                      Our team of experts will help you create an effective
                      social media strategy to achieve your business goals.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={realTime} alt="real Time Icon" />
                    </div>
                    <h3>Real Time and Data</h3>
                    <p>
                      Real-time data provides crucial insights into consumer
                      behavior and market trends. Our digital marketing
                      strategies leverage real-time data to help businesses stay
                      ahead of the competition
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <div className="seoserv-wrap">
            <div className="row">
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={onlineMedia} alt="Online Media Icon" />
                    </div>
                    <h3>Online Media Management</h3>
                    <p>
                      Online media management is a vital component of any
                      successful digital marketing strategy. It involves
                      managing and monitoring all online activities and
                      engagements with customers across various social media
                      platforms.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInUpBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={report} alt="Report Analysis Icon" />
                    </div>
                    <h3>Reporting &amp; Analysis</h3>
                    <p>
                      We provide insightful SEO reports that guide your analysis
                      and help you make informed decisions. Trust us to provide
                      you with the data and insights you need to drive growth
                      and success.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-md-4">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="seoservices">
                    <div className="seoimages">
                      <img src={penalty} alt="Penalty Recovery" />
                    </div>
                    <h3>Penalty Recovery</h3>
                    <p>
                      Our penalty recovery services help you identify and
                      address issues that may be holding back your website's
                      search engine performance. We use advanced techniques to
                      diagnose and fix penalties, allowing you to recover and
                      regain your website's rankings and traffic.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="websitePricing">
        <div className="titleSection">
          <span>Our Pricing</span>
          <h2>OUR PACKAGES</h2>
          <p>Affordable packages for powerful results - unlock your business potential with Shree Krishna Digital Marketing Agency.</p>
        </div>
        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
          <div className="container mt-5 d-flex justify-content-center align-items-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Basic Plan</h3>
                    <p>For Startup Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          6000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Monthly Tracking & Sharing Report.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Monthly Tracking & Sharing Report.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Meta Tagging.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>On page SEO.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Content Quality.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Image Optimization.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Internal links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>External Links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Updating information on basis of client requirement on
                          monthly basis if any.
                        </span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard centerCard">
                  <div className="pricingTitle">
                    <h3>Premium Plan</h3>
                    <p>for Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          12000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>{" "}
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Monthly Tracking & Sharing Report.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Technical SEO.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Meta Tagging.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Meta Tagging.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Content Quality.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Image Optimization.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Internal links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>External Links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Updating information on basis of client requirement on monthly basis if any.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>GMB (Google my business )Update work.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>GMB maintenance.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Two Blogs In one month.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0">
                <div className="pricingCard">
                  <div className="pricingTitle">
                    <h3>Classic Plan</h3>
                    <p>For Enterprise Business</p>
                    <div className="pricingPrice">
                      <h3>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span>
                          9000/-{" "}
                          <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                        </span>
                      </h3>
                    </div>
                  </div>
                  <div className="pricingPoint mb-3">
                    <ul>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Monthly Tracking & Sharing Report.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Technical SEO.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Meta Tagging.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>On page SEO.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Content Quality.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Image Optimization.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>Internal links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>External Links.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>
                          Updating information on basis of client requirement on
                          monthly basis if any.
                        </span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>GMB (Google my business )Update work.</span>{" "}
                      </li>
                      <li>
                        <i className="material-symbols-outlined">done</i>{" "}
                        <span>GMB maintenance.</span>{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="pricingButton">
                    <button className="btn">Book Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </section> */}

      <AnimationOnScroll animateIn="animate__fadeInRightBig" animateOnce={true}>
      <EnquiryForm toEmail="ansariarbaz254@gmail.com" leadSource="https://skdm.in" services="Search Engine Optimization"/>

      </AnimationOnScroll>

      <Footer />
    </>
  );
};

export default SEO;
