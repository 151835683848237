import React, { useState,useLayoutEffect } from 'react';
import "../css/Gallery.css";
import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";
// import flute from "../Images/flutelogo-removebg-preview.png"
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../components/GalleryData';
// import Bounce from 'react-reveal/Bounce';
// import Footer from '../component/Footer';

const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);
        
    }


    return (
        <>
 
       
 <Header />
      <section className="pricingBanner">
        <div className="container">
          <div className="row">
            {/* <div class="col-md-6 d-flex justify-content-center align-items-end">
              <div class="pricingImg">
                <img src="https://img.freepik.com/premium-vector/graph-profit-income-growth-teamwork-achieving-results-successful-business-concept_143808-842.jpg" alt="graph-profit" />
              </div>
            </div> */}

            <div className="col-md-12 d-flex justify-content-center align-items-center text-align-center">
              <div className="pricingText">
                <h1 style={{ fontSize: "3rem" }}>Life at Shree Krishna Digital Marketing</h1>
                <p>
                  Digital marketing has emerged as a rapidly growing field in
                  recent years, and Shree Krishna Digital Marketing can provide
                  excellent career growth opportunities for aspiring
                  professionals. Here are some reasons why a career in digital
                  marketing with Shree Krishna can be a great choice for your
                  professional growth
                </p>
                <div className="buttonsList">
                  <button className="btn">Know More </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Gallery