import React from 'react';
import "../css/Item.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Image } from 'antd';
// import Zoom from 'react-reveal/Zoom';

const Items = ({ data }) => {
    return (
        <>
            <div className='row'>
                {
                    data.map((value) => {
                        const {Category,CImage, CategoryName } = value

                        return (
                            <>
                                {/* <Zoom> */}
                               
               <div className="col-lg-3 col-md-6 mb-5">
              <div className="advisor-item">
                <div className="info-box">
                  <Image  src={CImage} alt="Thumb" />
                  {/* <div className="stick">
                    <p>Ganpati Festival</p>

                 
                </div> */}
              </div>
            </div>
          
         
          {/* <div className="row mt-lg-5 mt-2"> */}
          
            </div>
                                {/* </Zoom> */}
                            </>
                        )
                    })
                }

            </div>

            
        </>
    )
}

export default Items