import React from "react";
import "./App.css";
// import SideBar from "./components/Sidebar/SideBar";
import WebDevelopment from "./components/services/web_development";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Home from "./components/Home";
import DigitalMarketing from "./components/services/digital_marketing";
import SocialMarketing from "./components/services/Social_marketing";
import SEO from "./components/services/seo";
import GraphicDesign from "./components/services/graphic_design";
import PRAdvertising from "./components/services/pr_adverising";
import Photography from "./components/services/photography";
import YouTube from "./components/services/youtube";
import PaidAds from "./components/services/paid_ads";
import AboutUs from "./components/about_us";
import Blog from "./components/blog";
import ContactUs from "./components/contact_us";
import ComingSoon from "./components/coming_soon";
import Career from "./components/career";
import Blogsdetail from "./components/services/Blogsdetail";
import BlogData from "./components/services/blogdata";
import ErrorPage from "./components/services/ErrorPage";
import Gallery from "./components/Gallery";
function App() {
  return (
    <Router hashType="noslash">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/best-website-development-company-in-andheri" element={<WebDevelopment />} />
        <Route path="/social-media-marketing-in-andheri-mumbai" element={<SocialMarketing />} />
        <Route path="/best-digital-marketing-agency-in-mumbai" element={<DigitalMarketing />} />
        <Route path="/seo-agency-in-andheri-mumbai" element={<SEO />} />
        <Route path="/best-graphic-design-agency-in-mumbai" element={<GraphicDesign />} />
        <Route path="/pr-and-advertising" element={<ComingSoon />} />
        <Route path="/photography" element={<ComingSoon />} />
        <Route path="/you-tube-marketing" element={<ComingSoon />} />
        <Route path="/ppc-management-company-in-andheri" element={<PaidAds />} />
        <Route path="/about-us-digital-marketing-company-andheri-mumbai" element={<AboutUs />} />
        <Route path="/digital-marketing-blog" element={<Blog />} />
        <Route path="/contact-us-digital-marketing" element={<ContactUs />} />
        <Route path="/careers-in-digital-marketing-andheri" element={<Career />} />
        <Route path="/Gallery" element={<Gallery/>} />
        <Route path="/coming-soon" element={<ComingSoon />} />

        <Route path="*" element={<ErrorPage />} />
        {/* <Route path="/blogdetail" element={<Blogsdetail/>} /> */}

        {BlogData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={<Blogsdetail/>}
            />
          ))}
      </Routes>
    </Router>
  );
}

export default App;
