import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" />
                  <div className="cta-text">
                    <h4>Find us</h4>
                   <a href="https://goo.gl/maps/Kad3z7VnW3erzccM9" target="_blank"><span> Andheri West, Mumbai, Maharashtra 400053</span></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <a href="tel:+91 70213 90953" target="_blank"><span>+91 70213 90953</span></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-4 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" />
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <a href="mailto:shreekrishana09@gmail.com" target="_blank"><span>shreekrishana09@gmail.com</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img
                        src="https://github.com/SKDM1990/SKDMFolder/blob/main/images/logo2.png?raw=true"
                        className="img-fluid"
                        alt="logo"
                      />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      Shree Krishna Digital is a fully fledged Digital Marketing
                      Agency. Inaugurated in the year 2018 and located in
                      Mumbai, Shree Krishna Digital, supports the concept of
                      Digital India.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    <span>Follow us</span>
                    <a href="https://www.facebook.com/shree.krishnadigitalmarketing/">
                      <i className="fa fa-facebook facebook-bg" />
                    </a>
                    <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/?originalSubdomain=in">
                      <i className="fa fa-linkedin twitter-bg" />
                    </a>
                    <a href="https://www.instagram.com/shree.krishnadigitalmarketing/">
                      <i className="fa fa-instagram google-bg" />
                    </a>
                    <a href="https://wa.me/+917021390953">
                    <i class="fa fa-whatsapp watsapp-bg"/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/best-digital-marketing-agency-in-mumbai">Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/best-website-development-company-in-andheri">Website Development</Link>
                    </li>
                    <li>
                      <Link to="/social-media-marketing-in-andheri-mumbai">Social Media Marketing</Link>
                    </li>
                    <li>
                      <Link to="/seo-agency-in-andheri-mumbai">SEO Servives</Link>
                    </li>
                    <li>
                      <Link to="/best-graphic-design-agency-in-mumbai">Graphic Design</Link>
                    </li>
                    <li>
                      <Link to="/ppc-management-company-in-andheri">PPC Marketing</Link>
                    </li>
                    <li>
                      <Link to="/digital-marketing-blog">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/best-graphic-design-agency-in-mumbai">Graphic Design</Link>
                    </li>
                    <li>
                      <Link to="/careers-in-digital-marketing-andheri">Career Growth</Link>
                    </li>
                    <li>
                      <Link to="/about-us-digital-marketing-company-andheri-mumbai">About</Link>
                    </li>
                    <li>
                      <Link to="/contact-us-digital-marketing">Contact</Link>
                    </li>

                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Subscribe</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      Don’t miss to subscribe to our new feeds, kindly fill the
                      form below.
                    </p>
                  </div>
                  <div className="subscribe-form">
                    <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button>
                        <i className="fa fa-paper-plane" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2021, All Right Reserved{" "}
                    <a href="https://skdm.in">
                      Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  {/* <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <a href="/contact-us">Contact</a>
                    </li>
                  </ul> */}
                    <ul>
                    <li>
                      <Link to="/best-digital-marketing-agency-in-mumbai">Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/best-website-development-company-in-andheri">Website Development</Link>
                    </li>
                    <li>
                      <Link to="/social-media-marketing-in-andheri-mumbai">Social Media Marketing</Link>
                    </li>
                    <li>
                      <Link to="/seo-agency-in-andheri-mumbai">SEO Servives</Link>
                    </li>
                    <li>
                      <Link to="/best-graphic-design-agency-in-mumbai">Graphic Design</Link>
                    </li>
                    <li>
                      <Link to="/ppc-management-company-in-andheri">PPC Marketing</Link>
                    </li>
                    <li>
                      <Link to="/best-graphic-design-agency-in-mumbai">Graphic Design</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
