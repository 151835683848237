import React, { useLayoutEffect } from "react";
import Header from "../../Header_Footer/Header";
import Footer from "../../Header_Footer/footer";

import whyChooceUs from "../../images/website_type/whyChooceUs.png";

import image1 from "../../images/socialIcon/1.png";
import image2 from "../../images/socialIcon/2.png";
import image3 from "../../images/socialIcon/3.png";
import image4 from "../../images/socialIcon/4.png";
import image5 from "../../images/socialIcon/5.png";
import image6 from "../../images/socialIcon/6.png";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

import facebook from "../../images/socialIcon/facebook.png";
import instagram from "../../images/socialIcon/instagram.png";
import twitter from "../../images/socialIcon/twitter.png";
import linkedin from "../../images/socialIcon/linkedin.png";
import youtube from "../../images/socialIcon/youtube.png";
import pinterest from "../../images/socialIcon/pinterest.png";
import EnquiryForm from "../../form/enquiry_form";
import { MetaTags } from "react-meta-tags";
import {FaArrowAltCircleRight} from "react-icons/fa";
import {FaDotCircle} from "react-icons/fa";
import { Link } from "react-router-dom";

const DigitalMarketing = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>Best Digital Marketing Agency In Mumbai - SKDM</title>
        <meta
          name="description"
          content="Improve your brand's visibility with our best digital marketing services in Mumbai. Strategic marketing can help to increase growth and engagement."
        />
        <meta
          property="og:title"
          content="Best Digital Marketing Agency In Mumbai - SKDM"
        />
        <meta
          property="og:description"
          content="Improve your brand's visibility with our best digital marketing services in Mumbai. Strategic marketing can help to increase growth and engagement."
        />
        <meta
          name="keywords"
          content="Best Digital Marketing Company in Andheri, Best Digital Marketing Agency In Mumbai"
        />
        <link
          rel="canonical"
          href="https://skdm.in/best-digital-marketing-agency-in-mumbai"
        />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <div className="ditalMarktingSection">
        <section className="pricingBanner">
          <div className="container">
            <div className="row">
              <div class="col-md-6 d-flex justify-content-center align-items-end">
                <div class="pricingImg">
                  <img src="https://cdn.dribbble.com/users/2317423/screenshots/14354672/social_media_marketing_4x.jpg" alt="Social Media Management" />
                </div>
              </div>

              <div className="col-md-6 d-flex justify-content-center align-items-end">
                <div className="pricingText">
                  <h1>Digital Marketing Company in Andheri, Mumbai</h1>
                  <p>
                  Digital marketing aims to propel businesses and brands, fostering customer loyalty, and driving sales through innovative online strategies.
                  </p>
                  <div className="buttonsList">
                    <button className="btn">
                      Pricing{" "}
                      <i className="fa fa-angle-right" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatIsSocialMedia" style={{ background: "#eee" }}>
          <div className="titleSection">
            <span>About Digital Marketing</span>

            <AnimationOnScroll
              animateIn="animate__fadeInDownBig"
              animateOnce={true}
            >
              <h2>Your No.1 Digital Marketing Company in Andheri</h2>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce={true}
            >
              <p>
              Shree Krishna Digitals, based in Andheri, stands out as the leading choice for your digital marketing needs. Our approach goes beyond conventional marketing, ensuring your brand's sustainability and attracting increasing demand. By creating an exceptional virtual representation of your business, we enhance visual and personal appeal for your clients, freeing you to focus on your core competencies while we handle the rest.
              </p>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce={true}
            >
              {/* <p>
                A dynamic, data-driven social media marketing strategy can
                provide extraordinary results for your organisation and turn
                customers into brand evangelists. additional crucially, an
                inventive social media marketing approach effects your search
                engine optimisation (SEO) and digital marketing activities,
                resulting in additional leads and revenue streams for your
                company.
              </p> */}
            </AnimationOnScroll>
          </div>
        </section>

        {/* ======================================= Our Services ================================== */}

        <section className="card-category-2">
          <div className="titleSection">
            <span>OUR SERVICES</span>
            <h2>Digital Marketing Services</h2>
          </div>
          <div className="container">
            <div className="row">
            <div className="col-12 mt-2">
                <h3 className="mt-4"> <i className="me-3"><FaArrowAltCircleRight/></i>Website Developement And Design (Web Developement)</h3>
                    <p> At Shree Krishna Digital Marketing, we specialize in professional <Link className="internalLinking" to="/best-website-development-company-in-andheri">website development </Link>  that goes beyond aesthetics. Our skilled team combines design finesse with advanced technical know-how to create websites that are not only visually appealing but also highly functional and user-friendly. With a focus on responsive design and seamless navigation, we ensure that your website delivers an exceptional user experience across all devices, ultimately driving higher engagement and conversions for your business.</p>
                </div>
                <div className="col-12 mt-2">
                <h3 className="mt-4"> <i className="me-3"><FaArrowAltCircleRight/></i> Elevate Your Brand with Effective Search Engine Optimization (SEO)</h3>
                    <p>At the heart of digital marketing success lies <Link className="internalLinking" to="/seo-agency-in-andheri-mumbai">SEO </Link> - the art of strategic language that search engines like Google require for top search results. Our experts at Shree Krishna Digital Marketing Agency excel in optimizing visibility, ensuring your business ranks high.</p>
                </div>
                <div className="col-12 mt-3">
                    <h3> <i className="me-3"><FaArrowAltCircleRight/></i>Social Media Mastery by No.1 Digital Marketing Company in Andheri</h3>
                    <p>Managing social media extends beyond content creation—it's akin to a full-time job. At Shree Krishna Digital Marketing Agency, the onus of <Link className="internalLinking" to="/social-media-marketing-in-andheri-mumbai">social media management </Link> is on us, allowing you to concentrate on core business activities.</p>
                </div>
                <div className="col-12 mt-3">
                <h3> <i className="me-3"><FaArrowAltCircleRight/></i>Empower Your Online Presence with Technical SEO Company in Andheri</h3>
                    <p>Our technical SEO consultants fine-tune your website for enhanced technical optimization, providing a seamless and user-friendly experience.</p>
                </div>
                <div className="col-12 mt-3">
                <h3> <i className="me-3"><FaArrowAltCircleRight/></i>Achieve Precision with Pay-Per-Click (PPC) Marketing Company in Andheri</h3>
                    <p>Shree Krishna Digital Marketing Agency's  <Link className="internalLinking" to="/ppc-management-company-in-andheri">PPC marketing </Link> ensures precise targeting, accelerating the customer journey, and boosting conversion rates.</p>
                </div>
                <div className="col-12 mt-3">
                <h3> <i className="me-3"><FaArrowAltCircleRight/></i>Dominate Local Searches with Expert Local SEO Services Company in Andheri</h3>
                    <p>For local businesses, local SEO is paramount. Our conversion-focused local SEO services at Shree Krishna Digitals in Andheri bolster your business's visibility, resulting in revenue growth.</p>
                </div>
            </div>
          </div>
        </section>

        {/* ========================================= Why Choose Us =================================== */}

        <section className="xavier">
          <div className="container">
            <div className="titleSection">
              <span>REASON TO CHOOSE US</span>
              <h2>Why Choose Us</h2>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
            </div>
            {/* <div class="dmwhychoose"> */}
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 m-auto">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="digichoose">
                    <h3>
                    Why Choose SKDM - The Best Digital Marketing Agency in Mumbai
                    </h3>
                    <p>
                    At Shree Krishna Digital Marketing in Andheri, we're a comprehensive hub for marketing and branding, dedicated to surpassing competitor offerings in customer experience. As your all-inclusive digital marketing agency, we prioritize your business's profitability, making it the core of our operations. Choose us for unmatched digital growth.
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="digimar">
                    <img className="w-100" src="https://seotask.in/wp-content/uploads/2020/05/corporate-social-media-management-services-768x384.png" alt="Social Media Management Agency" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>
        <section className="talha" style={{ backgroundColor: "#fff" }}>
          <div className="titleSection">
            <span>GROWTH </span>
            <h2>Business Growth</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
          </div>
          <div className="container dmbg">
            {/* <div class="dmwhychoose"> */}
            <div className="row">
              <div className="col-lg-6 m-auto order-2 order-md-1">
                <AnimationOnScroll
                  animateIn="animate__fadeInLeftBig"
                  animateOnce={true}
                >
                  <div className="digibusigrowth">
                    <h2>
                      How does Social Media services drive business growth.
                    </h2>
                    <p>
                      Social media drives business growth by providing
                      businesses with a highly visible, accessible way to
                      connect with their audience. By building a strong social
                      media presence and engaging with customers through content
                      and conversation, businesses can build brand awareness,
                      establish themselves as thought leaders in their industry,
                      and drive traffic and sales. Social media advertising also
                      offers powerful targeting capabilities, allowing
                      businesses to reach specific audiences with personalized
                      messaging that resonates with them. <Link className="internalLinking" to="/best-website-development-company-in-andheri"> Also Know More About Our Website Development </Link>
                    </p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-6">
                <AnimationOnScroll
                  animateIn="animate__fadeInRightBig"
                  animateOnce={true}
                >
                  <div className="bgser">
                    <img src="https://www.apac-insider.com/wp-content/uploads/2022/02/Business-Growth-1.jpg" alt="Social Media Growth" />
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="websitePricing">
          <div className="titleSection">
            <span>Our Pricing</span>
            <h2>Our packages</h2>
            <p>
              Affordable packages for powerful results - unlock your business
              potential with Shree Krishna Digital Marketing Agency.
            </p>
          </div>
          <AnimationOnScroll
            animateIn="animate__fadeInDownBig"
            animateOnce={true}
          >
            <div className="container mt-5 d-flex justify-content-center align-items-center">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col p-0 m-0">
                  <div className="pricingCard">
                    <div className="pricingTitle">
                      <h3>Basic Package</h3>
                      <p>For Startup Business</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            7000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Any Two Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            10 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Confirmation before Posting.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Keywords Hashtag / Description.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way..
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis.</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="pricingCard centerCard">
                    <div className="pricingTitle">
                      <h3>Elite Package</h3>
                      <p>For Enterprise Business</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            25000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Three Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            12 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Confirmation before Posting.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Keywords Hashtag / Description.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>100 leads gurantee.</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="pricingCard">
                    <div className="pricingTitle">
                      <h3>Pro Package</h3>
                      <p>For Growing Buisness</p>
                      <div className="pricingPrice">
                        <h3>
                          <i className="fa fa-inr" aria-hidden="true" />
                          <span>
                            15000/-{" "}
                            <span style={{ fontSize: ".8rem" }}>per month</span>{" "}
                          </span>
                        </h3>
                      </div>
                    </div>
                    <div className="pricingPoint mb-3">
                      <ul>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Any Two Social Media Optimization.</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            10 image creatives 4 video posting in a Month.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>
                            Joining group & Post sharing and Increasing
                            Followers in Organic way.
                          </span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Report & Insights on Monthly Basis</span>{" "}
                        </li>
                        <li>
                          <i className="material-symbols-outlined">done</i>{" "}
                          <span>Generating 50 lead per month Gurantee</span>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="pricingButton">
                      <button className="btn">Book Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </section> */}

        <AnimationOnScroll
          animateIn="animate__fadeInLeftBig"
          animateOnce={true}
        >
          <EnquiryForm
            toEmail="shreekrishanadigital09@gmail.com"
            leadSource="https://skdm.in"
            services="Social Media Marketing"
          />
        </AnimationOnScroll>
      </div>
      <Footer />
    </>
  );
};

export default DigitalMarketing;
