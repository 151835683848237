import React from "react";
import "../Header_Footer/HeaderFooter.css";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MiniDrawer from "./LeftBar";
import { NavLink, useNavigate } from "react-router-dom";

const Header = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
    if (newValue === "one") {
      navigate("/best-website-development-company-in-andheri");
    }
    if (newValue === "two") {
      navigate("/social-media-marketing-in-andheri-mumbai");
    }
    if (newValue === "three") {
      navigate("/seo-agency-in-andheri-mumbai");
    }
    if (newValue === "four") {
      navigate("/best-graphic-design-agency-in-mumbai");
    }
    if (newValue === "five") {
      navigate("/pr-and-advertising");
    }

    if (newValue === "six") {
      navigate("/photography");
    }

    if (newValue === "seven") {
      navigate("/you-tube-marketing");
    }
    if (newValue === "eight") {
      navigate("/ppc-management-company-in-andheri");
    }
    if (newValue === "nine") {
      navigate("/best-digital-marketing-agency-in-mumbai");
    }
  };

  return (
    <>
      <header className="headerSection">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="navbar-socialLinks d-none d-md-block" id="socialId">
              <ul>
                <li>
                  <a href="https://www.facebook.com/search/top?q=shree%20krishna%20digital%20marketing">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/shree.krishnadigitalmarketing/?igshid=YmMyMTA2M2Y%3D">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/shree-krishna-digital-marketing/">
                    <i class="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/+917021390953">
                    <i class="fa fa-whatsapp" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <NavLink className="navbar-brand" to="/">
              <img
                className="logo1"
                src="https://github.com/SKDM1990/SKDMFolder/blob/main/images/footerLogo.png?raw=true"
                alt="company logo"
              />
            </NavLink>
            <MiniDrawer />
          </div>
        </nav>

        <div className="bottomNavbar">
          <Box
            sx={{
              flexGrow: 1,
              maxWidth: { xl: 1400, lg: 1200, md: 900, sm: 480, xs: 320 },
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
            >
              <Tab value="nine" label="DIGITAL MARKETING SERVICES" />
              <Tab value="one" label="WEBSITE DEVLOPMENT" />
              <Tab value="two" label="SOCIAL MEDIA MARKETING" />
              <Tab value="three" label="SEARCH ENGINE OPTIMIZATION" />
              <Tab value="four" label="GRAPHIC DESIGN" />
              <Tab value="eight" label="PPC SERVICES" />
              {/* <Tab value="five" label="PR AND ADVERTISING" /> */}
              {/* <Tab value="six" label="PHOTOGRAPHY" /> */}
              {/* <Tab value="seven" label="YOUTUBE MARKETING" /> */}
            </Tabs>
          </Box>
        </div>
      </header>
    </>
  );
};

export default Header;
