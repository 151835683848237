import { Alert } from "@mui/material";
import React, { useState } from "react";

const EnquiryForm = ({ toEmail, leadSource, services,  formType}) => {
  const [errorName, setErrorName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorSubject, setErrorSubject] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const ServiceEnquiry = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (data.get("name") === "") {
      setErrorName("Please Enter Name");
    } else {
      setErrorName("");
    }

    if (data.get("email") === "") {
      setErrorEmail("Please Enter Email");
    } else {
      setErrorEmail("");
    }

    if (data.get("phone") === "") {
      setErrorPhone("Please Enter phone");
    } else {
      setErrorPhone("");
    }

    if (data.get("subject") === "") {
      setErrorSubject("Please Enter subject");
    } else {
      setErrorSubject("");
    }

    if (data.get("message") === "") {
      setErrorMessage("Please Enter message");
    } else {
      setErrorMessage("");
    }

    const body =
      '<!DOCTYPE html><html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta name="x-apple-disable-message-reformatting"><title>Welcome - [Plain HTML]</title><link href="https://fonts.googleapis.com/css?family=Montserrat:300,500" rel="stylesheet"></head><body width="100%" bgcolor="#EEE" style="margin:0;mso-line-height-rule:exactly"><center style="width:100%;background:#F1F1F1;text-align:left"><div style="display:none;font-size:1px;line-height:1px;max-height:0;max-width:0;opacity:0;overflow:hidden;mso-hide:all;font-family:sans-serif">(Optional) This text will appear in the inbox preview, but not the email body.</div><div style="max-width:680px;margin:auto" class="email-container"><table role="presentation" cellspacing="0" cellpadding="0" border="0" align="center" width="100%" style="max-width:680px" class="email-container"><tr><td bgcolor="#302043"><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%"><tr><td style="padding:8px 0;text-align:center"><h2 style="color:#fff;font-weight:700;font-size:20px">' +
      data.get("subject") +
      ' Details</h2></td></tr></table></td></tr><tr><td bgcolor="#ffffff"><table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%"><br><tr><td style="padding:8px 20px 16px 30px;font-family:sans-serif;font-size:16px;line-height:12px;color:#666;font-weight:400"><h4 style="margin:0">Name : <span>' +
      data.get("name") +
      '</span></h4></td></tr><tr><td style="padding:0 20px 16px 30px;font-family:sans-serif;font-size:16px;line-height:12px;color:#666;font-weight:400"><h4 style="margin:0">Email :<span>' +
      data.get("email") +
      '</span></h4></td></tr><tr><td style="padding:0 20px 16px 30px;font-family:sans-serif;font-size:16px;line-height:14px;color:#666;font-weight:400"><h4 style="margin:0">Phone No :<span>' +
      data.get("phone") +
      '</span></h4></td></tr><tr><td style="padding:0 20px 16px 30px;font-family:sans-serif;font-size:16px;line-height:14px;color:#666;font-weight:400"><h4 style="margin:0">Message :<br><br><span>' +
      data.get("message") +
      '</span></h4></td></tr><tr style="background-color:grey"><td style="padding:8px 0;font-family:sans-serif;font-size:12px;line-height:18px;color:#111;text-align:center;font-weight:500"><p style="margin:0">Copyright &copy; <b>skdm.in</b>, All Rights Reserved.</p></td></tr></table></td></tr></table></div></center></body></html>';

    if (
      data.get("name") !== "" &&
      data.get("email") !== "" &&
      data.get("phone") !== "" &&
      data.get("subject") !== "" &&
      data.get("message") !== ""
    ) {
      fetch("https://skdm.in/server/v1/send_lead_mail.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          toEmail: toEmail,
          fromEmail: "skdmlead@gmail.com",
          mailSubject: "New Lead generation",
          mailBody: body,
          accountName: "SKDM",
          accountLeadSource: leadSource,
          accountLeadName: data.get("name"),
          accountLeadMsgs: data.get("message"),
          accountLeadPhone: data.get("phone"),
          accountLeadEmail: data.get("email"),
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data:", data);
        })
        .catch((error) => console.error(error));

      alert("Your form has been submitted. Our team will contact you soon.");

      e.target.reset();
    }
  };

  return (
    <>
      <section className="enquiryUs">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row justify-content-center">
                {formType === "job" ? (
                  ""
                ) : (
                  <div className="col-md-6">
                    <h3 className="heading enquiryTitle mb-4">
                      Let's talk about everything!
                    </h3>
                    <img
                      src="https://preview.colorlib.com/theme/bootstrap/contact-form-16/images/undraw-contact.svg"
                      alt="Image"
                      className="img-vector"
                      data-pagespeed-url-hash={827056895}
                    />
                  </div>
                )}
                <div className={formType ? `col-12`: `col-md-6`}>
                  <form
                    className="mb-5"
                    id="contactForm"
                    name="contactForm"
                    noValidate="novalidate"
                    onSubmit={ServiceEnquiry}
                  >
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Your name"
                        />
                      </div>
                      <span style={{ color: "red" }}>{errorName}</span>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <input
                          type="number"
                          className="form-control"
                          name="phone"
                          id="email"
                          max={10}
                          placeholder="Phone No."
                        />
                        <span style={{ color: "red" }}>{errorPhone}</span>
                      </div>
                      <div className="col-md-6 form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email"
                        />
                        <span style={{ color: "red" }}>{errorEmail}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          id="subject"
                          placeholder="Service"
                          value={services}
                        />
                        <span style={{ color: "red" }}>{errorSubject}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          cols={30}
                          rows={7}
                          placeholder="Write your message"
                          defaultValue={""}
                        />
                        <span style={{ color: "red" }}>{errorMessage}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mt-3">
                        <button type="submit" className="btn enquiryButton">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EnquiryForm;
