import React, { useLayoutEffect } from "react";
import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";

import "../css/About.css";

import mission from "../images/about/mission.png";
import vission from "../images/about/vission.png";

import { MdAddIcCall } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { MetaTags } from "react-meta-tags";

const AboutUs = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
          {/* ========= Seo ========== */}
          <MetaTags>
        <title>About Us | Digital Marketing Company in Andheri, Mumbai</title>
        <meta
          name="description"
          content="Know more about the digital marketing company in Andheri, Mumbai. Get in touch with us now for free consultation."
        />
        <meta
          property="og:title"
          content="About Us | Digital Marketing Company in Andheri, Mumbai"
        />
        <meta
          property="og:description"
          content="Know more about the digital marketing company in Andheri, Mumbai. Get in touch with us now for free consultation."
        />
        <meta
          name="keywords"
          content="About Us Digital Marketing Agency In Andheri, About Us Digital Marketing Company in Andheri,"
        />
        <link rel="canonical" href="https://skdm.in/about-us-digital-marketing-company-andheri-mumbai" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      <div className="aboutUs" id="aboutUsSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="aboutImg">
                <img
                  src="https://github.com/SKDM1990/SKDMFolder/blob/main/images/aboutUs1.png?raw=true"
                  alt="About Banner"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center">
              <div className="aboutUsTitle">
                <h1>About Us</h1>
                {/* <p>
                  Shree Krishna Digital is a fully fledged Digital Marketing
                  Agency. Inaugurated in the year 2018 and located in Mumbai,
                  Shree Krishna Digital, supports the concept of Digital India.
                  The main role of Shree Krishna Digital is to advertise the
                  start-ups and growing companies of India at a very good level
                  and at a very aggressive price
                </p> */}
                <p>Shree Krishna Digital Marketing is a dynamic

and versatile digital marketing agency that has

been providing comprehensive solutions to

businesses of all sizes and sectors since 2018.

Based in Mumbai, we pride ourselves on being

Andheri's most dynamic and adaptable online

marketing powerhouse. Our data-driven

approach and expertise in research and

marketing help us create a competitive

advantage for our clients across different

industries. Our goal is to help businesses boost

their online presence and thrive in the world of

technology through effective marketing

methods.</p>
                {/* <a href="#packageList">
                  <button className="btn packageButton">View More</button>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="theFounder">
        <div className="container">
          <div className="titleSection">
            <span>Best Digital Marketing Agency</span>
            <h2>Shree Krishna Digital Marketing</h2>
          </div>

          <div className="founderDetail">
            <div className="founderImage">
              <img
                src="https://github.com/SKDM1990/SKDMFolder/blob/main/images/footerLogo.png?raw=true"
                alt="About Banner"
              />
            </div>
            <div className="founderText">
              <p>
              Start With A Plan And Finish With Best Results Best Digital
                  Marketing Agency In Andheri.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="TeamSection">
        <div className="titleSection mb-5">
          <span>Our Team</span>
          <h2>Know Our Team</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-5">
              <div className="row">
                <div className="col-4">
                  <div className="teamImage">
                    <img
                      class="absolute object-cover w-full h-full rounded"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                      alt="Person"
                    />
                  </div>
                </div>
                <div className="col-8">
                  <h5>Oliver Aguilerra</h5>
                  <p class="mb-4 text-muted small">Product Manager</p>
                  <p class="mb-4 text-muted">
                    Vincent Van Gogh’s most popular painting, The Starry Night.
                  </p>

                  <div class="d-flex justify-content-start align-items-center dataIcons">
                    <a href="/" class="">
                      <MdAddIcCall />
                    </a>
                    <a
                      href="/"
                      class="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <GrMail />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="row">
                <div className="col-4">
                  <div className="teamImage">
                    <img
                      class="absolute object-cover w-full h-full rounded"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                      alt="Person"
                    />
                  </div>
                </div>
                <div className="col-8">
                  <h5>Oliver Aguilerra</h5>
                  <p class="mb-4 text-muted small">Product Manager</p>
                  <p class="mb-4 text-muted">
                    Vincent Van Gogh’s most popular painting, The Starry Night.
                  </p>

                  <div class="d-flex justify-content-start align-items-center dataIcons">
                    <a href="/" class="">
                      <MdAddIcCall />
                    </a>
                    <a
                      href="/"
                      class="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <GrMail />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5 mb-md-0">
              <div className="row">
                <div className="col-4">
                  <div className="teamImage">
                    <img
                      class="absolute object-cover w-full h-full rounded"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                      alt="Person"
                    />
                  </div>
                </div>
                <div className="col-8">
                  <h5>Oliver Aguilerra</h5>
                  <p class="mb-4 text-muted small">Product Manager</p>
                  <p class="mb-4 text-muted">
                    Vincent Van Gogh’s most popular painting, The Starry Night.
                  </p>

                  <div class="d-flex justify-content-start align-items-center dataIcons">
                    <a href="/" class="">
                      <MdAddIcCall />
                    </a>
                    <a
                      href="/"
                      class="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <GrMail />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-4">
                  <div className="teamImage">
                    <img
                      class="absolute object-cover w-full h-full rounded"
                      src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                      alt="Person"
                    />
                  </div>
                </div>
                <div className="col-8">
                  <h5>Oliver Aguilerra</h5>
                  <p class="mb-4 text-muted small">Product Manager</p>
                  <p class="mb-4 text-muted">
                    Vincent Van Gogh’s most popular painting, The Starry Night.
                  </p>

                  <div class="d-flex justify-content-start align-items-center dataIcons">
                    <a href="/" class="">
                      <MdAddIcCall />
                    </a>
                    <a
                      href="/"
                      class="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
                    >
                      <GrMail />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </section> */}

      <section className="MissionVission">
        <div className="titleSection">
          <span>Our Mission,Vission</span>
          <h2>Our Company Growth</h2>
        </div>
        <div className="container" style={{ maxWidth: "1" }}>
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <div className="missionVissionCard">
                <div className="cardImage">
                  <img src={mission} alt="About Mission" />
                </div>
                <div className="cardDiscription">
                  <h4>Our Mission</h4>
                  <p>
                  At Shree Krishna Digital Marketing, we understand the power of effective digital marketing in today’s competitive business environment. We are dedicated to helping businesses like yours reach new heights using the latest methods and technologies in the digital space. Through our comprehensive range of services, we equip you with the tools and expertise needed to grow your online presence, attract a targeted audience and enable you to measure results.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="missionVissionCard">
                <div className="cardImage">
                  <img src={vission} alt="About Vision" />
                </div>
                <div className="cardDiscription">
                  <h4>Our Vision</h4>
                  <p>
                  At Shree Krishna Digital Marketing, we envision a world where businesses of all sizes and types can harness the power of digital marketing to unlock their full potential. Our mission is to empower businesses to thrive in the digital landscape by delivering unique digital marketing services and strategies. We believe that by using the right digital tools, insights and know-how, any business can reach new heights of success and achieve sustainable growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
