import React, { useEffect, useLayoutEffect } from "react";
import Header from "../Header_Footer/Header";
import Footer from "../Header_Footer/footer";
import "../css/Home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper";

import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import slide_image_1 from "../images/services/1.png";
import slide_image_2 from "../images/services/2.png";
import slide_image_3 from "../images/services/3.png";
import slide_image_4 from "../images/services/4.png";
import slide_image_5 from "../images/services/5.png";
import slide_image_6 from "../images/services/6.png";
import slide_image_7 from "../images/services/7.png";
import slide_image_8 from "../images/services/8.png";

import trust from "../images/whyUsIcon/trust.png";
import certified from "../images/whyUsIcon/certificate.png";
import creativity from "../images/whyUsIcon/creativity.png";
import help from "../images/whyUsIcon/help.png";
import innovation from "../images/whyUsIcon/innovation.png";
import quality from "../images/whyUsIcon/quality.png";

import PortfolioData from "../Json/PortfolioData";
import { Avatar } from "@mui/material";

// ================= Technoloy Image ====================

import image1 from "../images/workingTechnologu/1.png";
import image2 from "../images/workingTechnologu/2.png";
import image3 from "../images/workingTechnologu/3.png";
import image4 from "../images/workingTechnologu/4.png";
import image5 from "../images/workingTechnologu/5.png";
import image6 from "../images/workingTechnologu/6.png";
import image7 from "../images/workingTechnologu/7.png";
import image8 from "../images/workingTechnologu/8.png";
import image9 from "../images/workingTechnologu/9.png";
import image10 from "../images/workingTechnologu/10.png";
import image11 from "../images/workingTechnologu/11.png";
import image12 from "../images/workingTechnologu/12.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import Dman from "../images/demoperson/man.jpg";
import Dwomen from "../images/demoperson/women.jpg";

import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Portfolio from "../Json/portfolio";
import { Table } from "antd";
import PackageComponent from "./Package/PackageComponent";

const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const [valueTab, setValueTab] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <>
      {/* ========= Seo ========== */}
      <MetaTags>
        <title>The Best Digital Marketing Agency In Andheri, Mumbai.</title>
        <meta
          name="description"
          content="Shree Krishna Digital Marketing Agency in Andheri, Mumbai is committed to helping businesses across industries augment."
        />
        <meta
          property="og:title"
          content="The Best Digital Marketing Agency In Andheri, Mumbai."
        />
        <meta
          property="og:description"
          content="Shree Krishna Digital Marketing Agency in Andheri, Mumbai is committed to helping businesses across industries augment."
        />
        <meta
          name="keywords"
          content="Best Digital Marketing Agency In Andheri, Digital Marketing Agency In Andheri Mumbai"
        />
        <link rel="canonical" href="https://skdm.in/" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      <Header />
      {/* ===================================== Home Section ==================== */}
      <div className="home" id="homeSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5">
              <div className="homeTitle">
                <h1>The Best Digital Marketing Agency In Andheri, Mumbai.</h1>
                <p>
                  Lift your business to new heights with us. Start With A Plan
                  And Finish With Best Results Best Digital Marketing Agency In
                  Andheri.
                </p>
                {/* <a href="//#packageList">
                  <button className="btn packageButton">Packages</button>
                </a> */}
              </div>
            </div>
            <div className="col-md-7"></div>
          </div>
        </div>
      </div>
      {/*=========================================== About Section ==============================*/}
      <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
        <section
          className="aboutSection"
          id="about"
          style={{ overflow: "hidden" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="titleSection">
                  <span>About Us</span>
                  <h2>The Digital Marketing Company In Andheri, Mumbai</h2>
                </div>
                <div className="aboutText">
                  <p className="firstPara">
                    Established in 2018, Shree Krishna digital marketing agency
                    in Andheri, Mumbai is committed to helping businesses across
                    industries augment. Our marketing efforts and gain a
                    competitive edge through comprehensive digital marketing
                    solutions. We are known as best digital marketing agency in
                    andheri Mumbai. Our Marketing Agency is an award-winning
                    digital marketing company that offers a full spectrum of
                    data-driven web marketing services. We develop
                    growth-oriented online marketing campaigns that make a
                    positive impact on businesses.
                  </p>
                  <p className="firstPara">
                    Strategic. Data-Driven. Resilient. That’s what you get when
                    you partner with the best digital marketing agency is shree
                    Krishna digital marketing agency. We are the digital &amp;
                    technology experts that help businesses &amp; individuals to
                    design, develop, implement &amp; track result-driven
                    solutions. We are the trusted name for end-to-end digital
                    marketing, logo designing, and website development in
                    andheri Mumbai.
                  </p>
                  {/* <p class="secondPara"> &nbsp; Shree Krishna Digital bids and wins here as we take every possible effort to first understand your offerings in your business as well as its long and short-term objectives and accordingly
                  do the needful.&nbsp; &nbsp;</i></p> */}
                </div>
                <div className="aboutButton">
                  {" "}
                  <NavLink to="/about-us">Know More</NavLink>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll>
      {/* ========================================== Service Section ======================================== */}
      <section className="serviceSection">
        <AnimationOnScroll
          animateIn="animate__fadeInRightBig"
          animateOnce={true}
        >
          <div className="titleSection">
            <span>Our Services</span>
            <h2>Our Best Services</h2>
            <p>We Provide Best Digitl Marketing Services</p>
          </div>
        </AnimationOnScroll>

        <div className="container">
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={7}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="swiper_container"
          >
            <SwiperSlide>
              <div
                className="serviceCard"
                onClick={()=>navigate(
                  "/best-website-development-company-in-andheri"
                )}
              >
                <img src={slide_image_1} alt="WEBSITE DESIGN AND DEVELOPMENT" />
                <h4>WEBSITE DESIGN AND DEVELOPMENT</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="serviceCard" onClick={()=>navigate("/")}>
                <img src={slide_image_2} alt="GRAPHIC DESIGN" />
                <h4>GRAPHIC DESIGN</h4>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="serviceCard" onClick={()=>navigate("/")}>
                <img src={slide_image_3} alt="YOU TUBE MARKETING" />
                <h4>YOU TUBE MARKETING</h4>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div
                className="serviceCard"
                onClick={()=>navigate("/seo-agency-in-andheri-mumbai")}
              >
                <img src={slide_image_4} alt="SEARCH ENGINE OPTIMIZATION" />
                <h4>SEARCH ENGINE OPTIMIZATION</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="serviceCard"
                onClick={()=>navigate("/ppc-management-company-in-andheri")}
              >
                <img src={slide_image_5} alt="PPC Services" />
                <h4>PAID ADS</h4>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="serviceCard" onClick={()=>navigate("/")}>
                <img src={slide_image_6} alt="PR AND ADVERTISING" />
                <h4>PR AND ADVERTISING</h4>
              </div>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <div className="serviceCard" onClick={()=>navigate("/")}>
                <img src={slide_image_7} alt="PHOTOGRAPHY" />
                <h4>PHOTOGRAPHY</h4>
              </div>
            </SwiperSlide> */}

            <SwiperSlide>
              <div
                className="serviceCard"
                onClick={()=>navigate("/best-digital-marketing-agency-in-mumbai")}
              >
                <img src={slide_image_8} alt="DIGITAL MARKETING" />
                <h4>DIGITAL MARKETING</h4>
              </div>
            </SwiperSlide>

            <div className="slider-controler">
              <div className="swiper-button-prev slider-arrow">
                <HiArrowSmLeft />
              </div>
              <div className="swiper-button-next slider-arrow">
                <HiArrowSmRight />
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </Swiper>
        </div>
      </section>
      {/* ======================================================== Our Portfolio =================================================== */}
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <section className="ourPortfolio">
          <div className="container-fluid section bg_light serv_dtl_indstr_sec_wrap">
            <div className="titleSection">
              <span>OUR AWESOME PORTFOLIO</span>
              <h2>Completed Project</h2>
              <p>Projects Completed With Our Best Digital Marketing Plans.</p>
            </div>
            <div className="container px-0">
              <Box
                sx={{
                  flexGrow: 1,
                  maxWidth: { lg: 900, xs: 320, sm: 480 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={valueTab}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {PortfolioData.map((category) => (
                    <Tab key={category.id} label={category.name} />
                  ))}
                </Tabs>
              </Box>
              <div className="portFolioDetails">
                <Portfolio valueTab={valueTab} PortfolioData={PortfolioData} />
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll>
      {/* ======= Why Us Section ========== */}
      <section className="whyUsSection">
        <div className="container">
          <div className="titleSection">
            <span>Why Choose Us</span>
            <h2>The Reason to choose us</h2>
            <p>
              We provide a wide range of services such as{" "}
              <Link
                className="homeWebsite"
                to="/best-website-development-company-in-andheri"
              >
                Website Developement & Design
              </Link>
              ,{" "}
              <Link
                className="homesearch"
                to="/social-media-marketing-in-andheri-mumbai"
              >
                search engine optimization
              </Link>
              ,{" "}
              <Link
                className="homesocial-media"
                to="/social-media-marketing-in-andheri-mumbai"
              >
                social media management
              </Link>
              , & Many More.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={trust} alt="trusted Company" />
                    <h4>Trusted</h4>
                    <p className="title">
                      In every project we handle, you can trust us to deliver
                      custom web solutions that meet your long-term strategic
                      goals.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInUpBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={certified} alt="Certified Company" />
                    <h4>Certified Company</h4>
                    <p className="title">
                      We are award-winning and certified online marketing
                      company that provides goal-oriented advertising solutions.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={help} alt="Supportive Team" />
                    <h4>Supportive Team</h4>
                    <p className="title">
                      We are boasts a unique, close-knit team with experts in
                      all facets of web marketing: strategic, creative and
                      technical.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInLeftBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={innovation} alt="High-Quality Services" />
                    <h4>High-Quality Services</h4>
                    <p className="title">
                      We are high quality full-service online digital marketing
                      agency. Attract, Impress, and Convert more leads online
                      and get results in your business.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInDownBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={quality} alt="Updated Trends Technologies" />
                    <h4>Updated Trends &amp; Technologies </h4>
                    <p className="title">
                      Our primary focus is to help businesses increase their
                      client retention rate and maximize conversion
                      opportunities so we are committed to update our strategies
                      for new trends and technologies.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
            <div className="col-md-6 col-lg-4">
              <AnimationOnScroll
                animateIn="animate__fadeInRightBig"
                animateOnce={true}
              >
                <div className="single-service">
                  <div className="part-1">
                    <img src={creativity} alt="Enhanced Strategies Ideas" />
                    <h4>Enhanced Strategies &amp; Ideas</h4>
                    <p className="title">
                      Our online marketing agency follows a systematic approach
                      to ensure all our online marketing efforts deliver
                      profitable results With our strategies and ideas.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            {/* / End Single Service */}
          </div>
        </div>
      </section>
      {/* ===================================================== Business Add =============================================== */}
      <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <section className="brandSection">
          <div className="container">
            <div className="companyAbout">
              <div className="smallText">Growth With US</div>
              <h3 className="title">We Help To Growth Your Business</h3>
              <p className="discription">
                Shree Krishna digital marketing agency is a dynamic, versatile
                and full-service online marketing company in Andheri Mumbai that
                doesn’t rely on smoke and mirrors to attract new clients.
                Instead, SKDM trusts its own search engine optimization (SEO)
                and marketing skills to drive new customers to our website. And
                we are help you to grow your business to another level.{" "}
              </p>
              <p className="discription">
                We’re not a static company. We don’t limit ourselves to specific
                industries. Shree Krishna digital marketing agency has the
                experience and professionals to build a custom website and use
                multiple digital marketing services to assist any size company
                in any industry.
              </p>
              {/* <button className="btn enquiryNowButton">
                <NavLink to="/contact-us">Enquiry Now</NavLink>
              </button> */}
            </div>
          </div>
        </section>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
        {/* <PackageComponent /> */}
      </AnimationOnScroll>
      {/* ===================================== PAckages Section ============================ */}
      {/* <AnimationOnScroll animateIn="animate__fadeInLeftBig" animateOnce={true}>
        <section
          className="packages pt-5"
          id="packageList"
          style={{ overflow: "hidden", background: "#fff" }}
        >
          <div className="titleSection mb-5">
            <span>Pricing</span>
            <h2>Our popular and all in one packages</h2>
            <p>
              The Ultimate Package For Our Digital Marketing Services Which Help
              To Grow Your Business More.
            </p>
          </div>
          <div className="container px-0 px-md-2">
            <div className="pricing pricing-palden">
              <div className="pricing-item">
                <div className="pricing-deco">
                  <svg
                    className="pricing-deco-img"
                    enableBackground="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xmlSpace="preserve"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0px"
                  >
                    <path
                      className="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    />
                    <path
                      className="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    />
                  </svg>
                  <h3 className="pricing-title silver">Silver</h3>
                  <div className="pricing-price">
                    <span className="pricing-currency">
                      <i className="fa fa-inr" aria-hidden="true" />
                    </span>
                    1,24,999/-
                  </div>
                  <h5 className="discountPrice">
                    <del>1,62,499</del> | 30%
                  </h5>
                </div>
                <div className="pricing-feature-data">
                  <ul className="pricing-feature-list">
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Dynamic Website with 5 pages.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Mailing System.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Mobile Friendly / Responsive.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hosting Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Domain Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      SSL Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Unique Content.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Free Domain Mail.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Basic SEO.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      GMB Maintenance.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hyperlinks.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span> 2
                      Social Media Platforms (100 Image Posts with Festive
                      Creative)
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      150 Leads.
                    </li>
                    <span className="material-symbols-outlined viewMorePackage">
                      expand_more
                    </span>
                  </ul>
                  <span className="material-symbols-outlined viewMorePackage">
                    expand_more
                  </span>
                </div>
                <button className="pricing-action">Book Now</button>
              </div>
              <div className="pricing-item pricing__item--featured">
                <div className="pricing-deco">
                  <svg
                    className="pricing-deco-img"
                    enableBackground="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xmlSpace="preserve"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0px"
                  >
                    <path
                      className="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    />
                    <path
                      className="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    />
                  </svg>
                  <h3 className="pricing-title platinum">Platinum</h3>
                  <div className="pricing-price">
                    <span className="pricing-currency">
                      <i className="fa fa-inr" aria-hidden="true" />
                    </span>
                    2,35,999/-
                  </div>
                  <h5 className="discountPrice">
                    <del>3,65,799</del> | 55%
                  </h5>
                </div>
                <div className="pricing-feature-data">
                  <ul className="pricing-feature-list">
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Dynamic Website with Unlimited pages.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Lead generation through Mail.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Control Panel with 5+ Funtionality
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      CMS Support + Training.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Customised Image or Video Banner Design for Home Page.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      SEO Friendly.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Social Media Live Page.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Logo ( 5 Options)
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Full Source Code.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Mobile Friendly / Responsive.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hosting Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Domain Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      SSL Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      All Browser Supported.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Customised Content.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Server Space.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Free Domain Mail.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      GMB Maintenance.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Video Testimonials.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hyperlinks.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span> 2
                      Social Media Platforms (140 Image Creative, 60 Video
                      Creative &amp; Festive Creative)
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Youtube Integration in Website (If you have).
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      600 Leads.
                    </li>
                  </ul>
                  <span className="material-symbols-outlined viewMorePackage">
                    expand_more
                  </span>
                </div>
                <button className="pricing-action">Book Now</button>
              </div>
              <div className="pricing-item">
                <div className="pricing-deco">
                  <svg
                    className="pricing-deco-img"
                    enableBackground="new 0 0 300 100"
                    height="100px"
                    id="Layer_1"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 300 100"
                    width="300px"
                    x="0px"
                    xmlSpace="preserve"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0px"
                  >
                    <path
                      className="deco-layer deco-layer--1"
                      d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729
	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--2"
                      d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729
	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z"
                      fill="#FFFFFF"
                      opacity="0.6"
                    />
                    <path
                      className="deco-layer deco-layer--3"
                      d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716
	H42.401L43.415,98.342z"
                      fill="#FFFFFF"
                      opacity="0.7"
                    />
                    <path
                      className="deco-layer deco-layer--4"
                      d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428
	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z"
                      fill="#FFFFFF"
                    />
                  </svg>
                  <h3 className="pricing-title gold">Gold</h3>
                  <div className="pricing-price">
                    <span className="pricing-currency">
                      <i className="fa fa-inr" aria-hidden="true" />
                    </span>
                    1,64,999/-
                  </div>
                  <h5 className="discountPrice">
                    <del>2,39,249</del> | 45%
                  </h5>
                </div>
                <div className="pricing-feature-data">
                  <ul className="pricing-feature-list">
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Dynamic Website with 5 pages.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Lead generation through Mail.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Control Panel with 2 Funtionality
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Customised Image Banner Design for Home Page.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Logo (2 Options).
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Full Source Code.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Mobile Friendly / Responsive.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hosting Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Domain Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      SSL Free.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      All Browser.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Unique Content.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Server Space.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Free Domain Mail.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Basic SEO.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      GMB Maintenance.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      Hyperlinks.
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span> 2
                      Social Media Platforms (110 Image Creative, 50 Video
                      Creative with Festive Creative).
                    </li>
                    <li className="pricing-feature">
                      <span className="material-symbols-outlined">done</span>{" "}
                      250 Leads.
                    </li>
                  </ul>
                  <span className="material-symbols-outlined viewMorePackage">
                    expand_more
                  </span>
                </div>
                <button className="pricing-action">Book Now</button>
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll> */}
      {/* ================================================== Our Growth ========================= */}
      {/* <section className="text-center" id="defaultProgressbar">
        <div className="titleSection mb-5">
          <span>Our Growth</span>
          <h2>Growth and Success of SKMD</h2>
        </div>
        <div className="container">
          <div className="row justify-content-center"></div>
          <div className="row align-items-center mt-6">
            <div className="col-md-6 position-relative">
              <div
                className="progress-circle mb-4"
                data-progress-circle='{"progress":88,"subText":"<span class=&apos;text-700 pt-2&apos;>More Power behind<br/>every pixel</span>"}'
              >
                <svg viewBox="0 0 100 100">
                  <path
                    d="M 50,50 m 0,-49 a 49,49 0 1 1 0,98 a 49,49 0 1 1 0,-98"
                    stroke="#eee"
                    strokeWidth={2}
                    fillOpacity={0}
                  />
                  <path
                    className="secondPath"
                    d="M 50,50 m 0,-49 a 49,49 0 1 1 0,98 a 49,49 0 1 1 0,-98"
                    stroke="#302043"
                    strokeWidth={2}
                    fillOpacity={0}
                  />
                  <defs>
                    <linearGradient
                      id="gradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0%" stopColor="#1970e2" />
                      <stop offset="100%" stopColor="#4695ff" />
                    </linearGradient>
                  </defs>
                </svg>
                <div
                  className="progressbar-text"
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    padding: 0,
                    margin: 0,
                    transform: "translate(-50%, -50%)",
                    color: "rgb(85, 85, 85)",
                  }}
                >
                  <span className="value">
                    <b className="count" data-count={90}>
                      0
                    </b>
                    <b>%</b>
                  </span>{" "}
                  <span>
                    <span className="text-700 pt-2">
                      More Power behind
                      <br />
                      every pixel
                    </span>
                  </span>
                </div>
              </div>
              <h4>Record Breaking in History</h4>
              <p className="px-md-4">
                Creating with sparrow is an easier, faster way to get things
                done. And it makes development even more effortless.
              </p>
            </div>
            <div className="col-md-6 ps-md-7 text-start mt-5 mt-md-0">
              <div
                className="progress-line me-4"
                data-progress-line='{"progress":93,"subText":"Google Page Speed"}'
              >
                <svg
                  viewBox="0 0 100 1"
                  preserveAspectRatio="none"
                  style={{
                    width: "100%",
                    height: "0.25rem",
                    strokeLinecap: "round",
                    borderRadius: "0.125rem",
                  }}
                >
                  <path
                    d="M 0,0.5 L 100,0.5"
                    stroke="#eee"
                    strokeWidth={1}
                    fillOpacity={0}
                  />
                  <path
                    className="graph graph1"
                    d="M 0,0.5 L 100,0.5"
                    stroke="#302043"
                    strokeWidth={1}
                    fillOpacity={0}
                    style={{ strokeDasharray: "100, 100" }}
                  />
                </svg>
                <div
                  className="progressbar-text"
                  style={{ color: "rgb(51, 51, 51)" }}
                >
                  <span className="value">
                    <b className="count" data-count={93}>
                      0
                    </b>
                    <b>%</b>
                  </span>{" "}
                  <span>Google Page Speed</span>
                </div>
              </div>
              <div
                className="progress-line me-4 mt-4"
                data-progress-line='{"progress":96,"subText":"Pingdom Page Speed"}'
              >
                <svg
                  viewBox="0 0 100 1"
                  preserveAspectRatio="none"
                  style={{
                    width: "100%",
                    height: "0.25rem",
                    strokeLinecap: "round",
                    borderRadius: "0.125rem",
                  }}
                >
                  <path
                    d="M 0,0.5 L 100,0.5"
                    stroke="#eee"
                    strokeWidth={1}
                    fillOpacity={0}
                  />
                  <path
                    className="graph graph2"
                    d="M 0,0.5 L 100,0.5"
                    stroke="#302043"
                    strokeWidth={1}
                    fillOpacity={0}
                    style={{ strokeDasharray: "100, 100" }}
                  />
                </svg>
                <div
                  className="progressbar-text"
                  style={{ color: "rgb(51, 51, 51)" }}
                >
                  <span className="value">
                    <b className="count" data-count={96}>
                      0
                    </b>
                    <b>%</b>
                  </span>{" "}
                  <span>Pingdom Page Speed</span>
                </div>
              </div>
              <div
                className="progress-line me-4 mt-4"
                data-progress-line='{"progress":90,"subText":"Sparrow Performance Matrix"}'
              >
                <svg
                  viewBox="0 0 100 1"
                  preserveAspectRatio="none"
                  style={{
                    width: "100%",
                    height: "0.25rem",
                    strokeLinecap: "round",
                    borderRadius: "0.125rem",
                  }}
                >
                  <path
                    d="M 0,0.5 L 100,0.5"
                    stroke="#eee"
                    strokeWidth={1}
                    fillOpacity={0}
                  />
                  <path
                    className="graph graph3"
                    d="M 0,0.5 L 100,0.5"
                    stroke="#302043"
                    strokeWidth={1}
                    fillOpacity={0}
                    style={{ strokeDasharray: "100, 100" }}
                  />
                </svg>
                <div
                  className="progressbar-text"
                  style={{ color: "rgb(51, 51, 51)" }}
                >
                  <span className="value">
                    <b className="count" data-count={90}>
                      0
                    </b>
                    <b>%</b>
                  </span>{" "}
                  <span>Sparrow Performance Matrix</span>
                </div>
              </div>
              <p className="mt-4">
                Combined with the power of bootstrap, sparrow can provide
                helpful elements to make web design easier and make your website
                load faster than ever.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* ============================================== Testimonial Section =================================== */}
      <section className="testimonialSection" id="testimonial">
        <AnimationOnScroll animateIn="animate__fadeInUpBig" animateOnce={true}>
          <div className="titleSection mb-5">
            <span>Testimonials</span>
            <h2>What our client say about us</h2>
          </div>
        </AnimationOnScroll>
        <div className="container mb-5">
          <div className="testimonialList">
            {/* Swiper */}

            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="testimonialCard">
                  <i
                    className="fa fa-quote-left qouateIcon"
                    aria-hidden="true"
                  />
                  <div className="testimonialText">
                    <p>
                      They are very good with their services . Their backhand
                      team is quite friendly and they willing to help me
                      whenever i approach them.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                  </ul>
                  <div className="userDetail">
                    <div className="userImg">
                      <img src={Dman} alt="profile icon" />
                    </div>
                    <div className="userName">
                      <h3>Tarun Srivastava</h3>
                      <span>Mumbai</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialCard">
                  <i
                    className="fa fa-quote-left qouateIcon"
                    aria-hidden="true"
                  />
                  <div className="testimonialText">
                    <p>
                      Good Ambiance and a great environment for learning and
                      growth. So far I have noticed the team environment is
                      healthy and approachable. Good Opportunity for fresher and
                      experience.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                  </ul>
                  <div className="userDetail">
                    <div className="userImg">
                      <img src={Dman} alt="profile icon" />
                    </div>
                    <div className="userName">
                      <h3>Abhishek Yadav</h3>
                      <span>Mumbai</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialCard">
                  <i
                    className="fa fa-quote-left qouateIcon"
                    aria-hidden="true"
                  />
                  <div className="testimonialText">
                    <p>
                      It was a great experience with you guys, You work great
                      and punctuality is great too ,all thanx to you guys.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                  </ul>
                  <div className="userDetail">
                    <div className="userImg">
                      <img src={Dman} alt="profile icon" />
                    </div>
                    <div className="userName">
                      <h3>Raunak maurya</h3>
                      <span>Mumbai</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialCard">
                  <i
                    className="fa fa-quote-left qouateIcon"
                    aria-hidden="true"
                  />
                  <div className="testimonialText">
                    <p>
                      I recently used Shree Krishna Digital Marketing Company in
                      Andheri for my business website and was very pleased with
                      the results.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                  </ul>
                  <div className="userDetail">
                    <div className="userImg">
                      <img src={Dman} alt="profile icon" />
                    </div>
                    <div className="userName">
                      <h3>AnilRaj</h3>
                      <span>Mumbai</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="testimonialCard">
                  <i
                    className="fa fa-quote-left qouateIcon"
                    aria-hidden="true"
                  />
                  <div className="testimonialText">
                    <p>Best digital marketing company in Andheri West.</p>
                  </div>
                  <ul>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true" />
                    </li>
                  </ul>
                  <div className="userDetail">
                    <div className="userImg">
                      <img src={Dwomen} alt="profile icon" />
                    </div>
                    <div className="userName">
                      <h3>Nandita</h3>
                      <span>Mumbai</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      {/* ================================================ Our Working Technology ========================================= */}
      <section
        className="ourWorkingTechnology"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="titleSection mb-5">
          <span>Technology</span>
          <h2>Our working technologies</h2>
          <p>
            Below Are The Modern Technologies We Use To Create A Powerful
            Website.
          </p>
        </div>
        <AnimationOnScroll
          animateIn="animate__fadeInDownBig"
          animateOnce={true}
        >
          <div className="container">
            <div className="row row-cols-md-4 row-cols-2">
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image1} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image2} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image3} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image4} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image5} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image6} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image7} alt="portfolio" />
                </div>
              </div>
              {/* <div className="col mb-3">
              <div
                className="portfolioCardList"
                style={{ maxWidth: 200, margin: "auto" }}
              >
                <img src={image8}  alt="portfolio" />
              </div>
            </div> */}
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image9} alt="portfolio" />
                </div>
              </div>
              {/* <div className="col mb-3">
              <div
                className="portfolioCardList"
                style={{ maxWidth: 200, margin: "auto" }}
              >
                <img src={image8}  alt="portfolio" />
              </div>
            </div> */}
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image10} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image11} alt="portfolio" />
                </div>
              </div>
              <div className="col mb-3">
                <div
                  className="portfolioCardList"
                  style={{ maxWidth: 200, margin: "auto" }}
                >
                  <img src={image12} alt="portfolio" />
                </div>
              </div>
            </div>
          </div>
        </AnimationOnScroll>
      </section>
      {/* =================================================== working process =================================== */}
      <section className="border-top" id="defaultProcess">
        <div className="titleSection mb-5">
          <span>Process</span>
          <h2>Our working process</h2>
          <p>
            We Follow The Best Work Process In Order To Give Our Clients Best
            Results And Sales.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-lg-5">
              <div className="row align-items-end">
                <div className="col-lg-6 order-lg-2 text-center">
                  <AnimationOnScroll
                    animateIn="animate__fadeInLeftBig"
                    animateOnce={true}
                  >
                    <img
                      className="img-fluid"
                      src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-01.svg"
                      alt="working process"
                      width={400}
                    />
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5 ">
                  <AnimationOnScroll
                    animateIn="animate__fadeInUpBig"
                    animateOnce={true}
                  >
                    <div className="process-item ms-5 ms-sm-8 ms-lg-5">
                      <span className="process-item-number">01</span>
                      <h3>Plan</h3>
                      <p>
                        We set priorities, organize content, and understand the
                        buyer’s journey that your audience takes as they
                        navigate your website. At the end of strategy, you will
                        have a Blueprint for your website project, a
                        comprehensive strategic plan for your website design,
                        content, and functionality.
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 border-end border-bottom text-center py-lg-5">
                  <AnimationOnScroll
                    animateIn="animate__fadeInRightBig"
                    animateOnce={true}
                  >
                    <img
                      className="img-fluid"
                      src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-02.svg"
                      alt="working process"
                      width={400}
                    />
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-6 pb-lg-5 mt-5 mb-5">
                  <AnimationOnScroll
                    animateIn="animate__fadeInUpBig"
                    animateOnce={true}
                  >
                    <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                      <span className="process-item-number">02</span>
                      <h3>Design</h3>
                      <p>
                        Once the Blueprint is approved, we create wireframes and
                        a design comp for your review. This process involves
                        various reviews, approvals, and close communication
                        between you and our creative team. We begin with the
                        Homepage to set style, image and branding standards,
                        then move toward interior layouts.
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 order-lg-2 text-center align-self-center">
                  <AnimationOnScroll
                    animateIn="animate__fadeInLeftBig"
                    animateOnce={true}
                  >
                    <img
                      className="img-fluid"
                      src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-03.svg"
                      alt="working process"
                      width={400}
                    />
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-6 border-start border-bottom pb-lg-5 mt-5 mb-5">
                  <AnimationOnScroll
                    animateIn="animate__fadeInUpBig"
                    animateOnce={true}
                  >
                    <div className="process-item ms-5 ms-sm-8 ms-lg-6">
                      <span className="process-item-number">03</span>
                      <h3>Develop</h3>
                      <p>
                        With the blueprint &amp; design comps as our guide, the
                        development team begins to create your website with the
                        state of the earth tools. Our entire team works to add
                        content, final design elements, review, &amp; test your
                        website for quality.
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="row pb-1">
                <div className="col-lg-6 border-end text-center py-8">
                  <AnimationOnScroll
                    animateIn="animate__fadeInRightBig"
                    animateOnce={true}
                  >
                    <img
                      className="img-fluid"
                      src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/illustration/process-04.svg"
                      alt="working process"
                      width={400}
                    />
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-6 align-self-center pb-lg-5 mt-5 mb-5">
                  <AnimationOnScroll
                    animateIn="animate__fadeInUpBig"
                    animateOnce={true}
                  >
                    <div className="process-item process-item-last ms-5 ms-sm-8 ms-lg-6">
                      <span className="process-item-number">04</span>
                      <h3>Deploy</h3>
                      <p>
                        After testing and review, we present your new website.
                        Upon your approval, your website will be launched,
                        promoted and optimized for search engines such as Google
                        &amp; Bing.
                      </p>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end of .container*/}
      </section>
      <Footer />
    </>
  );
};

export default Home;
